// 需求公示废包公告
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'

// 查询需求公式废包列表
export function queryList(data) {
  return fetch({
    url: `/${prefix}/biDemandPublicResult/list/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 需求公式结果保存(修改)/提交
export function saveOrCommit(data) {
  return fetch({
    url: `/${prefix}/biDemandPublicResult/result/saveOrCommit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 需求公式结果详情查询
export function queryResultDetail(data) {
  return fetch({
    url: `/${prefix}/biDemandPublicResult/resultDetail/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 查询需求公式结果审核列表
export function queryAduitList(data) {
  return fetch({
    url: `/${prefix}/biDemandPublicResult/aduitList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 审核需求公式废包结果
export function aduitDemandPublicResult(data) {
  return fetch({
    url: `/${prefix}/biDemandPublicResult/demandPublicResult/aduit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 生成（查询）需求公式废包公告
export function queryDemandPublicWasteNotice(data) {
  return fetch({
    url: `/${prefix}/biDemandPublicResult/demandPublicWasteNotice/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 保存/发布需求公式废包公告
export function saveOrPublish(data) {
  return fetch({
    url: `/${prefix}/biDemandPublicResult/demandPublicWasteNotice/saveOrPublish`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

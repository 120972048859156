/**
 * @desc 参与投标 - 项目登记
 */
import { fetch } from '../../../fetch' // 引入fecth.js


// const prefix = 'crIp'
const prefix = 'czy-busi'
/**
 * @desc 部门分派列表
 */
export function getDeptList(data) {
  return fetch({
    url: `/${prefix}/demand/dept/list`,
    method: 'post',
    data
  })
}

/**
 * @desc 分派保存
 */
export function deptSave(data) {
  return fetch({
    url: `/${prefix}/demand/planDispatch/save`,
    method: 'post',
    data
  })
}

/**
 * @desc 分派退回
 */
export function handleDemandBack(data) {
  return fetch({
    url: `/${prefix}/demand/back`,
    method: 'post',
    data,
  })
}
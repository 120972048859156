/**
 * @desc 汇聚单点登录
 */
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylrIp'
const prefix = 'czy-admin'
/**
 * @desc 获取登录URL
 */
export function getLoginUrl(params) {
  return fetch({
    url: `/${prefix}/singleLogin/getLoginUrl`,
    method: 'get',
    params: params,
  })
}

/**
 * @desc 获取登录后回调接口
 */
export function loginReturn(params) {
  return fetch({
    url: `/${prefix}/singleLogin/loginReturn`,
    method: 'get',
    params: params,
  })
}


/**
 * @desc 获取登录验证码
 */
export function captcha(params) {
  return fetch({
    url: `/${prefix}/auth/mock/captcha`,
    method: 'get',
    params: params,
  })
}

// 供应商管理
import { fetch } from '../../../fetch' // 引入fecth.js

const prefix = 'czy-admin'

//供应商管理
export function providerList(data) {// 查询供应商管理列表
  return fetch({ url: `/${prefix}/provider/list`, method: 'post', data: JSON.stringify(data), })
}

export function providerDetail(providerNo) {// 供应商查看
  return fetch({ url: `/${prefix}/provider/view?providerNo=${providerNo}`, method: 'get', data: {}, })
}

export function updateRegister(data) {// 供应商修改
  return fetch({ url: `/${prefix}/registe/updateRegister`, method: 'post', data: JSON.stringify(data), })
}


//代理机构/监管机构管理
export function dictOrganizeInfo(data) {// 代理机构/监管机构管理列表
  return fetch({ url: `/${prefix}/dictOrganizeInfo/pageList`, method: 'post', data: JSON.stringify(data), })
}

export function saveOrUpdate(data) {// 代理机构/监管机构新增/修改
  return fetch({ url: `/${prefix}/dictOrganizeInfo/saveOrUpdate`, method: 'post', data: JSON.stringify(data), })
}

export function organizeDelete(id) {// 代理机构/监管机构删除
  return fetch({ url: `/${prefix}/dictOrganizeInfo/delete?id=${id}`, method: 'get', data: {}, })
}

export function organizeDetail(id) {// 代理机构/监管机构查看
  return fetch({ url: `/${prefix}/dictOrganizeInfo/detail?id=${id}`, method: 'get', data: {}, })
}


//采购人管理
export function budgetUnitQuery(data) {// 采购人管理列表
  return fetch({ url: `/${prefix}/budgetUnit/list`, method: 'post', data: JSON.stringify(data), })
}

export function budgetUnitSave(data) {// 采购人新增
  return fetch({ url: `/${prefix}/budgetUnit/save`, method: 'post', data: JSON.stringify(data), })
}

export function budgetUnitUpdate(data) {// 采购人修改
  return fetch({ url: `/${prefix}/budgetUnit/update`, method: 'post', data: JSON.stringify(data), })
}

export function budgetUnitDelete(unitNo) {// 采购人删除
  return fetch({ url: `/${prefix}/budgetUnit/delete?unitNo=${unitNo}`, method: 'get', data: {}, })
}

export function budgetUnitDetail(unitNo) {// 采购人查看
  return fetch({ url: `/${prefix}/budgetUnit/view?unitNo=${unitNo}`, method: 'get', data: {}, })
}
import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//合同列表
export function list(data) {
  return fetch({
    url: '/czy-busi/contractEntity/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//合同详情
export function info(data) {
  return fetch({
    url: '/czy-busi/contractEntity/info',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//供应商下拉列表
export function supplierList(data) {
  return fetch({
    url: '/czy-busi/contractEntity/supplierList',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//合同保存或提交
export function save(data) {
  return fetch({
    url: '/czy-busi/contractEntity/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//退回供应商/退回采购人
export function back(data) {
  return fetch({
    url: '/czy-busi/contractEntity/back',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//供应商签订
export function submit(data) {
  return fetch({
    url: '/czy-busi/contractEntity/submit',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//合同公示详情
export function publicityInfo(data) {
  return fetch({
    url: '/czy-busi/contractEntity/publicityInfo',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//发布合同公示
export function publicityPublish(data) {
  return fetch({
    url: '/czy-busi/contractEntity/publicityPublish',
    method: 'post',
    data: JSON.stringify(data)
  });
}
import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//列表
export function list(data) {
  return fetch({
    url: '/czy-admin/template/responseFile/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//保存
export function save(data) {
  return fetch({
    url: '/czy-admin/template/responseFile/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//详情
export function detail(data) {
  return fetch({
    url: '/czy-admin/template/responseFile/detail',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//删除
export function remove(data) {
  return fetch({
    url: '/czy-admin/template/responseFile/remove',
    method: 'post',
    data: JSON.stringify(data)
  });
}
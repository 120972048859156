import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//开标一览表列表
export function openingBidlist(data) {
  return fetch({
    url: '/czy-admin/template/openingBid/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//开标模板保存
export function save(data) {
  return fetch({
    url: '/czy-admin/template/openingBid/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//开标模板详情
export function detail(data) {
  return fetch({
    url: '/czy-admin/template/openingBid/detail',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//删除
export function remove(data) {
  return fetch({
    url: '/czy-admin/template/openingBid/remove',
    method: 'post',
    data: JSON.stringify(data)
  });
}
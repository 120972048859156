/**
 * @desc  委托协议签订 接口方法文件
 */
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

/**
 * @desc 获取 协议列表（采购人、代理机构）列表
 */
export function getList(data = {}) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/list`,
        method: 'post',
        data,
    })
}
/**
 * @desc 获取 计划列表
 */
export function getPlanList(data = {}) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/planSelectionList`,
        method: 'post',
        data,
    })
}
/**
 * @desc 新增委托协议保存（采购人 提交）
 */
export function addAgreement(data) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/add`,
        method: 'post',
        data,
    })
}

/**
 * @desc 委托协议设置查询（代理机构）
 * @param uuid - 唯一ID
 */
export function queryProtocolSet(params) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/protocolSet/query`,
        method: 'get',
        params,
    })
}

/**
 * @desc 生成委托协议文件（采购人 线上签订）
 */
export function createDocument(data) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/document`,
        method: 'post',
        data,
    })
}

/**
 * @desc 生成委托代理协议合同（代理机构 线上签订）
 * @param uuid - 唯一ID
 */
export function createContract(params) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/contract`,
        method: 'get',
        params,
    })
}

/**
 * @desc 委托协议资格审查负责方设置保存（代理机构 线上签订）
 * @param {uuid: '唯一ID',templateName:'协议模板（1.公开招标-甲方负责资格审查；2.公开招标-甲方和乙方共同负责资格审查;3.公开招标-甲方授权乙方资格审查）'}
 */
export function saveProtocolSet(data) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/protocolSet/save`,
        method: 'post',
        data,
    })
}

/**
 * @desc 委托协议提交（代理机构 提交）
 */
export function agencySubmit(data) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/submit`,
        method: 'post',
        data,
    })
}

/**
 * @desc 获取上传委托协议合同地址（代理机构 线下签订）
 */
export function queryContract(params) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/contract/query`,
        method: 'get',
        params,
    })
}

/**
 * @desc 上传委托协议合同地址保存（代理机构 线下签订） 线下签订）
 */
export function saveContract(data) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/contract/save`,
        method: 'post',
        data,
    })
}

/**
 * @desc 委托协议审核(采购人)
 */
export function auditContract(data) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/audit`,
        method: 'post',
        data,
    })
}

/**
 * @desc 委托协议退回(采购人、代理机构 )
 */
export function backContract(data) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/back`,
        method: 'post',
        data,
    })
}

/**
 * @desc 删除委托协议（采购人)
 */
export function deleteContract(params) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/delete`,
        method: 'get',
        params,
    })
}

/**
 * @desc 委托协议退回记录查询
 */
export function queryBackRecord(params) {
    return fetch({
        url: `/${prefix}/planAgencyAgreementBack/query`,
        method: 'get',
        params,
    })
}

export function queryAgreementAudit(params) {//委托协议审核查询
    return fetch({ url: `/${prefix}/planAgencyAgreementAuditEntity/query`, method: 'get', params, })
}

/**
 * @desc 委托协议退回(采购人、代理机构 )
 */
export function backAgreement(data) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/back`,
        method: 'post',
        data,
    })
}

/**
 * @desc 获取配置
 */
export function getConfig(params) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/getConfig`,
        method: 'get',
        params,
    })
}

/**
 * @desc 获取配置
 */
export function getConfigDescription(params) {
    return fetch({
        url: `/${prefix}/config/description`,
        method: 'get',
        params,
    })
}

/**
 * @desc  采购人盖章提交
 */

export function updateSubmit(data) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/updateSubmit`,
        method: 'post',
        data,
    })
}

/**
 * @desc 查看pdf
 */

export function lookPdf(params) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/lookPdf`,
        method: 'get',
        params,
    })
}

/**
 * @desc 代理服务费填写保存
 */
export function agentServiceFeesave(data) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/online/agentServiceFee/save`,
        method: 'POST',
        data,
    })
}
/**
 * @desc 代理服务费信息查询
 */
export function getAagentServiceFee(params) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/online/agentServiceFee/query`,
        method: 'get',
        params,
    })
}

/**
 * @desc 委托协议甲乙方信息查询
 */
export function getABInfo(params) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/online/firstSecond/query`,
        method: 'get',
        params,
    })
}
/**
 * @desc 委托协议甲乙方信息查询
 */
export function saveABInfo(data) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/online/firstSecond/save`,
        method: 'post',
        data,
    })
}

// 盖章生成pdf 返回文件信息
export function sealContract(params) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/contract/seal`,
        method: 'get',
        params
    })
}

// 获取最新盖章生成pdf
export function getPdf(params) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/contract/getPdf`,
        method: 'get',
        params
    })
}

//草拟委托协议ofd推送前置机
export function entrustmentAgreementPushOfd(data) {
    return fetch({ url: `/${prefix}/planAgencyAgreement/entrustmentAgreementPushOfd`, method: 'post', data, })
}

//草拟委托协议推送前置机
export function entrustmentAgreementPushMessage(data) {
    return fetch({ url: `/${prefix}/planAgencyAgreement/entrustmentAgreementPushMessage`, method: 'post', data, })
}

//根据uuid查询预算－委托协议表ofd地址
export function getPlanAgencyAgreeMentByuuid(data) {
    return fetch({ url: `/${prefix}/planAgencyAgreement/getPlanAgencyAgreeMentByuuid`, method: 'post', data, })
}

//代理委托协议盖章提交
export function uploadAgencyAgreementMessage1(data) {
    return fetch({ url: `/${prefix}/planAgencyAgreement/uploadAgencyAgreementMessage1`, method: 'post', data, })
}

/**
 * @desc 委托代理合同列表删除
 */
export function deleteAgreement(data = {}) {
    return fetch({
        url: `/${prefix}/planAgencyAgreement/deleteAgreement`,
        method: 'post',
        data,
    })
}
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'
const prefix2 = 'czy-project'


// 专家签到/回避列表及流程总状态查询
export function queryBidQuotationProject(data) {
    return fetch({ url: `/${prefix}/bid/quotation/bidQuotationProject/query`, method: 'post', data: JSON.stringify(data), })
}
// 查看供应商报价列表
export function querySupplierQuotationList(data) {
    return fetch({ url: `/${prefix}/bid/quotation/supplierQuotationList/query`, method: 'post', data: JSON.stringify(data), })
}

// 查看供应商报价明细列表
export function querySupplierQuotationDetailList(data) {
    return fetch({ url: `/${prefix}/bid/quotation/supplierQuotationDetailList/query`, method: 'post', data: JSON.stringify(data), })
}

// 查看供应商最终报价列表
export function querySupplierSecondaryQuotationList(data) {
    return fetch({ url: `/${prefix}/bid/quotation/supplierSecondaryQuotationList/query`, method: 'post', data: JSON.stringify(data), })
}
// 开启本包评标室
export function openBidEvaluationOfficeByBao(data) {
    return fetch({ url: `/${prefix}/bid/quotation/bidEvaluationOfficeByBao/open`, method: 'post', data: JSON.stringify(data), })
}
// 本包废标
export function wasteBao(data) {
    return fetch({ url: `/${prefix}/bid/quotation/bao/waste`, method: 'post', data: JSON.stringify(data), })
}
// 一次报价解密
export function decodeFirst(data) {
    return fetch({ url: `/${prefix}/bid/quotation/decryptFirstPriceAndFileState`, method: 'post', data: JSON.stringify(data), }, false, 1000 * 60 * 3)
}
// 一次报价解密结果
export function decodeFirstResult(data) {
    return fetch({ url: `/${prefix}/bid/quotation/decryptFirstPriceAndFileResult`, method: 'post', data: JSON.stringify(data), }, false)
}

// 二次报价解密 
export function startSecondPriceDecrypt(data) {
    return fetch({ url: `/${prefix}/bid/quotation/bao/startSecondPriceDecrypt`, method: 'post', data: JSON.stringify(data), }, false)
}
// 发送解密验证码
export function sendSmsVerificationCode(data) {
    return fetch({ url: `/${prefix}/bid/quotation/sendSmsVerificationCode`, method: 'post', data: JSON.stringify(data), })
}
// 验证解密验证码
export function contrastSmsVerificationCode(data) {
    return fetch({ url: `/${prefix}/bid/quotation/contrastSmsVerificationCode`, method: 'post', data: JSON.stringify(data), }, false)
}

// 延长二次报价解密时间
export function delaySecondPriceDecrypt(data) {
    return fetch({ url: `/${prefix}/bid/quotation/bao/delaySecondPriceDecrypt`, method: 'post', data: JSON.stringify(data), }, false)
}

// 结束二次报价
export function endSecondQuotation(data) {
    return fetch({ url: `/${prefix}/bid/quotation/bao/endSecondQuotation`, method: 'post', data: JSON.stringify(data), }, false)
}

// 导出最终报价记录表
export function exportSecondaryQuotationList(data) {
    return fetch({ url: `/${prefix}/bid/quotation/supplierSecondaryQuotationList/export`, method: 'post', data: JSON.stringify(data), responseType: 'blob' })
}
// 导出报价汇总表
export function exportQuotationSummaryList(data) {
    return fetch({ url: `/${prefix}/bid/quotation/supplierQuotationSummaryList/export`, method: 'post', data: JSON.stringify(data), responseType: 'blob' })
}
// 导出报价汇总表
export function exportOpenBidResponseRecordList(data) {
    return fetch({ url: `/${prefix}/bid/quotation/openBidResponseRecordList/export`, method: 'post', data: JSON.stringify(data), responseType: 'blob' })
}


//开标大厅

// 查询包开标状态
export function getBidKbStatus(data) {
    return fetch({ url: `/${prefix2}/bid/opening/getBidKbStatus`, method: 'post', data: JSON.stringify(data), })
}

// 修改包开标状态
export function updateKbStatus(data) {
    return fetch({ url: `/${prefix2}/bid/opening/updateKbStatus`, method: 'post', data: JSON.stringify(data), })
}

// 查询包的投标人报名、投标、签到家数
export function queryProviderNum(data) {
    return fetch({ url: `/${prefix2}/bid/opening/queryProviderNum`, method: 'post', data: JSON.stringify(data), })
}

// 查询包的投标人签到/解密/确认列表
export function queryProviderList(data) {
    return fetch({ url: `/${prefix2}/bid/opening/queryProviderList`, method: 'post', data: JSON.stringify(data), })
}

// 查询解密状态
export function decryptInfo(data) {
    return fetch({ url: `/${prefix2}/bid/opening/decryptInfo`, method: 'post', data: JSON.stringify(data), })
}

// 延长解密时间
export function delayDecrypt(data) {
    return fetch({ url: `/${prefix2}/bid/opening/delayDecrypt`, method: 'post', data: JSON.stringify(data), })
}

// 查询确认状态
export function confirmInfo(data) {
    return fetch({ url: `/${prefix2}/bid/opening/confirmInfo`, method: 'post', data: JSON.stringify(data), })
}



// 查询异议答复列表
export function questionAndAnswerQuery(data) {
    return fetch({ url: `/${prefix2}/bid/opening/questionAndAnswerQuery`, method: 'post', data: JSON.stringify(data), }, false)
}

//代理机构异议答复
export function answerQuerstion(data) {
    return fetch({ url: `/${prefix2}/bid/opening/answerQuerstion`, method: 'post', data: JSON.stringify(data), })
}

// 开标结束-查询签到信息
export function providerSignInfoQuery(data) {
    return fetch({ url: `/${prefix2}/bid/opening/providerSignInfoQuery`, method: 'post', data: JSON.stringify(data), })
}

//导出供应商签到表
export function exportProviderSignInfo(data) {
    return fetch({ url: `/${prefix}/bid/quotation/exportProviderSignInfo`, method: 'post', data: JSON.stringify(data), })
}

//下载开标记录表文件
export function exportOpenBidRecord(data) {
    return fetch({ url: `/${prefix2}/bid/opening/exportOpenBidRecord`, method: 'post', data: JSON.stringify(data), })
}

// 查询会议信息
export function queryMeetingRoomInfo(data) {
    return fetch({ url: `/${prefix}/meetingroom/queryMeetingRoomInfo`, method: 'post', data: JSON.stringify(data), })
}

//保存会议信息
export function addOrUpdateMeetingRoomInfo(data) {
    return fetch({ url: `/${prefix}/meetingroom/addOrUpdateMeetingRoomInfo`, method: 'post', data: JSON.stringify(data), })
}

//取消会议
export function cancelMeetingRoomInfo(data) {
    return fetch({ url: `/${prefix}/meetingroom/cancelMeetingRoomInfo`, method: 'post', data: JSON.stringify(data), })
}

//开启直播
export function openLive(data) {
    return fetch({ url: `/${prefix}/meetingroom/openLive`, method: 'post', data: JSON.stringify(data), })
}

//开启录制
export function openRecord(data) {
    return fetch({ url: `/${prefix}/meetingroom/openRecord`, method: 'post', data: JSON.stringify(data), })
}

//可用的会议室列表
export function listCanUsedRoom(data) {
    return fetch({ url: `/${prefix}/meetingroom/listCanUsedRoom`, method: 'get', params: data, })
}

//可用的会议室列表
export function uploadExpertExtractDemand(data) {
    return fetch({ url: `/${prefix}/projectExpertApplication/expertApplication/uploadExpertExtractDemand`, method: 'post', params: data, })
}
// 中标通知书管理接口
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

/**
 * @desc 中标通知书列表
 */
export function getList(data) {
  return fetch({
    url: `/${prefix}/project/outbid/notice/list`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 查看 包-供应商（代理机构）
 */
export function getPackage(data) {
  return fetch({
    url: `/${prefix}/project/outbid/notice/look`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 提交（代理机构）
 */
export function onSubmit(data) {
  return fetch({
    url: `/${prefix}/project/outbid/notice/submit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 下载
 */
export function getDownloadUrl(data) {
  return fetch({
    url: `/${prefix}/project/outbid/notice/getDownloadUrl`,
    method: 'post',
    data,
  })
}

/**
 * @desc 盖章
 */
export function onSeal(params) {
  return fetch({
    url: `/${prefix}/project/outbid/notice/seal`,
    method: 'get',
    params,
  })
}

/**
 * @desc 批量生成
 */
export function batchGeneration(params) {
  return fetch({
    url: `/${prefix}/project/outbid/notice/batchGeneration`,
    method: 'get',
    params,
  })
}

/**
 * @desc 包 -  下载设置
 */
export function downloadSettings(data) {
  return fetch({
    url: `/${prefix}/project/outbid/notice/downloadSettings`,
    method: 'post',
    data,
  })
}

/**
 * @desc 批量导出并归档
 */

export function batchExportArchive(data) {
  return fetch({
    url: `/${prefix}/project/outbid/notice/batchExportArchive`,
    method: 'post',
    data,
    responseType: 'blob'
  })
}

/**
 * @desc 详情
 */
export function getDetail(params) {
  return fetch({
    url: `/${prefix}/project/outbid/notice/info`,
    method: 'get',
    params,
  })
}

/**
 * @desc 代理机构审核
 */
export function audit(data) {
  return fetch({
    url: `/${prefix}/project/outbid/notice/audit`,
    method: 'post',
    data,
  })
}

/**
 * @desc 采购人确认
 */
export function onConfirm(data) {
  return fetch({
    url: `/${prefix}/project/outbid/notice/confirm`,
    method: 'post',
    data,
  })
}

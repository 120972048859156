import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//一般配置项保存
export function save(data) {
  return fetch({
    url: '/czy-admin/config/commonDiff/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//一般配置项详情
export function info(data) {
  return fetch({
    url: '/czy-admin/config/commonDiff/info',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//一般配置项复制区域
export function copy(data) {
  return fetch({
    url: '/czy-admin/config/commonDiff/copy',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//企业扣减比例配置保存
export function enterpriseBidRatesave(data) {
  return fetch({
    url: '/czy-admin/config/enterpriseBidRate/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 企业扣减比例配置详情
export function enterpriseBidRateinfo(data) {
  return fetch({
    url: '/czy-admin/config/enterpriseBidRate/info',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 企业扣减比例复制区划
export function enterpriseBidRatecopy(data) {
  return fetch({
    url: '/czy-admin/config/enterpriseBidRate/copy',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//远程异地评标配置保存
export function remoteMeetingsave(data) {
  return fetch({
    url: '/czy-admin/config/remoteMeeting/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 远程异地评标配置详情
export function remoteMeetinginfo(data) {
  return fetch({
    url: '/czy-admin/config/remoteMeeting/info',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 远程异地评标复制区划
export function remoteMeetingcopy(data) {
  return fetch({
    url: '/czy-admin/config/remoteMeeting/copy',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//需求委托设置配置保存
export function requireDelegatesave(data) {
  return fetch({
    url: '/czy-admin/config/requireDelegate/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 需求委托设置配置详情
export function requireDelegateinfo(data) {
  return fetch({
    url: '/czy-admin/config/requireDelegate/info',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 需求委托设置复制区划
export function requireDelegatecopy(data) {
  return fetch({
    url: '/czy-admin/config/requireDelegate/copy',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 监管机构大屏
import { fetch } from '../../../../fetch' // 引入fecth.js
const prefix = 'czy-project'


// 数据统计
export function projectStatistic(data) {
    return fetch({ url: `/${prefix}/screen/projectStatistic`, method: 'post', data: JSON.stringify(data), })
}

// 采购方式数量占比
export function procurementMethods(data) {
    return fetch({ url: `/${prefix}/screen/procurementMethods`, method: 'post', data: JSON.stringify(data), })
}

// 采购类型数量占比
export function procurementCategories(data) {
    return fetch({ url: `/${prefix}/screen/procurementCategories`, method: 'post', data: JSON.stringify(data), })
}

// 地图
export function regionData(data) {
    return fetch({ url: `/${prefix}/screen/regionData`, method: 'post', data: JSON.stringify(data), })
}

// 各区资金使用情况
export function RegionFundingUsage(data) {
    return fetch({ url: `/${prefix}/screen/regionFundingUsage`, method: 'post', data: JSON.stringify(data), })
}

// 查询选中的重点项目最新节点
export function keyProjectsList(data) {
    return fetch({ url: `/${prefix}/screen/keyProjects/list`, method: 'post', data: JSON.stringify(data), })
}

// 查询选中的重点项目最新节点
export function keyProjectsListPage(data) {
    return fetch({ url: `/${prefix}/screen/keyProjects/listPage`, method: 'post', data: JSON.stringify(data), })
}

// 重点项目最新节点搜索
export function keyProjectsSearch(data) {
    return fetch({ url: `/${prefix}/screen/keyProjects/search`, method: 'post', data: JSON.stringify(data), })
}

// 重点项目最新节点新增
export function keyProjectsAdd(data) {
    return fetch({ url: `/${prefix}/screen/keyProjects/add`, method: 'post', data: JSON.stringify(data), })
}

// 重点项目最新节点删除
export function keyProjectsDel(data) {
    return fetch({ url: `/${prefix}/screen/keyProjects/delete`, method: 'post', data: JSON.stringify(data), })
}

// 百度天气接口
export function weatherInfo(data) {
    return fetch({ url: `/${prefix}/screen/weatherInfo`, method: 'post', data: JSON.stringify(data), })
}




// 投诉数量
export function getComplaints(data) {
    return fetch({ url: `/${prefix}/screen/complaints`, method: 'post', data: JSON.stringify(data), })
}

// 获取百度地图区域掩码
export function getLonglat(data) {
    return fetch({ url: `/${prefix}/screen/getLonglat`, method: 'post', data: JSON.stringify(data), })
}

export function getMapData(areaCode) {
	return fetch({ url: `/${prefix}/screen/getMapData?areaCode=${areaCode}`, method: 'get' }); 
}
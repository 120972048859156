import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//列表
export function list(data) {
  return fetch({
    url: '/czy-busi/correctNotice/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//详情
export function info(data) {
  return fetch({
    url: '/czy-busi/correctNotice/info',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//变更中标人获取候选人
export function getCandidate(data) {
  return fetch({
    url: '/czy-busi/correctNotice/getCandidate',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//保存
export function save(data) {
  return fetch({
    url: '/czy-busi/correctNotice/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//提交
export function submit(data) {
  return fetch({
    url: '/czy-busi/correctNotice/submit',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//提交
export function revocation(data) {
  return fetch({
    url: '/czy-busi/correctNotice/revocation',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//发布
export function publish(data) {
  return fetch({
    url: '/czy-busi/correctNotice/publish',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//生成预览内容
export function generatePreviewContent(data) {
  return fetch({
    url: '/czy-busi/correctNotice/generatePreviewContent',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//审核列表
export function auditList(data) {
  return fetch({
    url: '/czy-busi/correctNotice/auditList',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//审核
export function audit(data) {
  return fetch({
    url: '/czy-busi/correctNotice/audit',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//退回
export function back(data) {
  return fetch({
    url: '/czy-busi/correctNotice/back',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//实质性条款变动问题 WebSocket 查看
export function ck(data) {
  return fetch({
    url: '/czy-busi/bid/clauseChange/ws/ck',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//webSocket 设置已读
export function read(params) {
  return fetch({
    url: '/czy-busi/webSocketMsg/read',
    method: 'get',
    params: params
  });
}
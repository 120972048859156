import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//待开标已开标列表
export function projectExpertsList(data) {
  return fetch({
    url: '/czy-busi/openbid/projectExpertsList',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//资格性审查详情
export function qualificationList(data) {
  return fetch({
    url: '/czy-busi/be/qualification/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//添加采购人代表
export function savePurchaseChecker(data) {
  return fetch({
    url: '/czy-busi/be/qualification/savePurchaseChecker',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//设置一键通过
export function approveAll(data) {
  return fetch({
    url: '/czy-busi/be/qualification/approveAll',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function deletePurchaseChecker(data) {
  return fetch({
    url: '/czy-busi/be/qualification/deletePurchaseChecker',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//审查弹框详情 供应商侧
export function detail(data) {
  return fetch({
    url: '/czy-busi/be/qualification/detail',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//审查弹框提交 供应商侧
export function save(data) {
  return fetch({
    url: '/czy-busi/be/qualification/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//采购人签字
export function purchaseSignQualify(data) {
  return fetch({
    url: '/czy-project/be/purchase/signQualify',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//代理机构签字
export function agencySignQualify(data) {
  return fetch({
    url: '/czy-project/be/agency/signQualify',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//查看签到表
export function signInfoList(data) {
    return fetch({
      url: '/czy-busi/qualifyReview/signInfoList',
      method: 'post',
      data: JSON.stringify(data)
    });
}
//查看当前所处状态
export function checkReviewStatus(data) {
    return fetch({
      url: '/czy-busi/qualifyReview/status',
      method: 'post',
      data: JSON.stringify(data)
    }, false);
}
//资格性审格人员签到
export function reviewDoSign(data) {
    return fetch({
      url: '/czy-busi/qualifyReview/sign',
      method: 'post',
      data: JSON.stringify(data)
    });
}
//提交审资格性审查核结果
export function reviewDoSubmit(data) {
    return fetch({
      url: '/czy-busi/qualifyReview/submit',
      method: 'post',
      data: JSON.stringify(data)
    });
}
//重新审查
export function reviewDoReCheck(data) {
    return fetch({
      url: '/czy-busi/qualifyReview/reCheck',
      method: 'post',
      data: JSON.stringify(data)
    });
}
//确认审查结果
export function reviewDoConfirmResult(data) {
    return fetch({
      url: '/czy-busi/qualifyReview/confirmResult',
      method: 'post',
      data: JSON.stringify(data)
    });
}
//审核结果确认和未确认名单
export function reviewConfirmList(data) {
    return fetch({
      url: '/czy-busi/qualifyReview/confirmList',
      method: 'post',
      data: JSON.stringify(data)
    },false);
}
//审查人员 资格性审查列表
export function reviewQualifyList(data) {
    return fetch({
      url: '/czy-busi/qualifyReview/qualifyList',
      method: 'post',
      data: JSON.stringify(data)
    });
}
//代理机构 资格性审查人员列表
export function reviewListInfo(data) {
    return fetch({
      url: '/czy-busi/qualifyReview/list',
      method: 'post',
      data: JSON.stringify(data)
    });
}
//代理机构 资审人员查询
export function reviewQueryPeopleList(data) {
    return fetch({
      url: '/czy-busi/qualifyReview/queryPeople',
      method: 'post',
      data: JSON.stringify(data)
    });
}
//代理机构 录入审查人员
export function reviewSavePeople(data) {
    return fetch({
      url: '/czy-busi/qualifyReview/savePeople',
      method: 'post',
      data: JSON.stringify(data)
    });
}
//代理机构 保存项目审查人员信息
export function reviewSavePeopleProject(data) {
    return fetch({
      url: '/czy-busi/qualifyReview/savePeopleProject',
      method: 'post',
      data: JSON.stringify(data)
    });
}
//查询当前机构用户名是否唯一
export function userGetSingleInfo(params) {
	return fetch({
		url: '/czy-admin/user/getSingleUserInfo',
		method: 'get',
		params: params
	});
}
//查看资格性审查报告
export function reviewViewReport(data) {
    return fetch({
      url: '/czy-busi/qualifyReview/viewReport',
      method: 'post',
      data: JSON.stringify(data)
    });
}
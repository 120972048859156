/**
 * @desc 导出公告
 */
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

/**
 * @desc 导出公告
 */
export function exportNotice(params) {
  return fetch({
    url: `/${prefix}/projectNoticeInfoEntity/exportNotice`,
    method: 'get',
    responseType: 'blob',
    params,
  })
}

import { fetch } from '../../../fetch' // 引入fecth.js
import { fetchhj, iofofetchhj, fetchhjpromens } from '../../../fetchhj' // 引入fecth.js
import qs from "qs"; // 引入qs
// const prefix = 'crIp'
const prefix = 'czy-project'

/**
 * @desc 供应商开标大厅列表
 */
export function queryOpeningList(data) {
  return fetch({
    url: `/${prefix}/bid/opening/queryOpeningList`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

//签到
export function signIn(data) {
  return fetch({
    url: `/${prefix}/bid/opening/signIn`,
    method: 'get',
    params: data
  })
}

//供应商-查询当前投标人用户所在项目包的开标签到解密确认状态
export function getBidProviderKbStatus(data) {
  return fetch({
    url: `/${prefix}/bid/opening/getBidProviderKbStatus`,
    method: 'get',
    params: data
  })
}

//供应商解密状态以及倒计时
export function decryptInfo(data) {
  return fetch({
    url: `/${prefix}/bid/opening/decryptInfo`,
    method: 'post',
    data
    // params: data
  })
}

//根据包状态判断流程到哪一步
//供应商-查询当前投标人用户所在项目包的开标签到解密确认状态
export function getBidKbStatus(data) {
  return fetch({
    url: `/${prefix}/bid/opening/getBidKbStatus`,
    method: 'post',
    data: data
  })
}

// 获取电话以及倒计时
export function bidQuotationProjecttime(data) {
  return fetch({
    url: `/czy-busi/bid/quotation/bidQuotationProject/query`,
    method: 'post',
    data
  })
}

//代理-查询包的投标人报名、投标、签到家数
export function queryProviderNum(data) {
  return fetch({
    url: `/${prefix}/bid/opening/queryProviderNum`,
    method: 'post',
    data
  })
}

//查询包的投标人签到/解密/确认列表
export function queryProviderList(data) {
  return fetch({
    url: `/${prefix}/bid/opening/queryProviderList`,
    method: 'post',
    data
  })
}

// 开标结束列表查询
export function endBidList(data) {
  return fetch({
    url: `/${prefix}/bid/opening/endBid/query`,
    method: 'post',
    data
  })
}

//签到信息列表查询
export function providerSignInfoQuery(data) {
  return fetch({
    url: `/${prefix}/bid/opening/providerSignInfoQuery`,
    method: 'post',
    data
  })
}

//供应商查询确认状态 待确认/已确认
export function confirmInfo(data) {
  return fetch({
    url: `/${prefix}/bid/opening/confirmInfo`,
    method: 'post',
    data
  })
}

//供应商获取投标文件加密密钥
export function getEncryptedKey(data) {
  return fetch({
    url: `/czy-busi/hj/provider/getEncryptedKey`,
    method: 'post',
    data
  })
}

//供应商解密投标文件
export function decryptBidFile(data) {
  return fetch({
    url: `/czy-busi/hj/provider/decryptBidFile`,
    method: 'post',
    data
  })
}

//供应商查询解密进度
export function decryptProgress(data) {
  return fetchhjpromens({
    url: `/czy-busi/hj/provider/decryptProgress`,
    method: 'post',
    data
  })
}



let bd = 'http://127.0.0.1:15322'

// 汇聚ca证书
export function gethjCaImage(data) {
  return iofofetchhj({
    url: `${bd}/api/ukey/getUkeyId`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    params: data
  });
}
//汇聚 获取证书信息
export function getUkeyCertificate(data) {
  return iofofetchhj({
    url: `${bd}/api/ukey/getUkeyCertificate`,
    method: 'get',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    params: data
  });
}

//汇聚解密
export function decryptDataSm2(data) {
  return iofofetchhj({
    url: `${bd}/api/ukey/decryptDataSm2?${data}`,
    method: 'post',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  });
}

// 汇聚CA证书签名
export function signData(data) {
  return iofofetchhj({
    url: `${bd}/api/ukey/signData`,
    method: 'post',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data
  });
}

// 调用汇聚签章助手
export function batchFileSign(data) {
  return iofofetchhj({
    url: `${bd}/external/batchFileSign`,
    method: 'post',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data
  });
}

//检查文件是否以打开盖章
export function checkFileOpen(data) {
  return fetch({ url: `/czy-busi/sign/hbca/checkFileOpen`, method: 'post', data })
}

//去掉盖章校验
export function closeOpenOfd(data) {
  return fetch({ url: `/czy-busi/sign/hbca/closeOpenOfd`, method: 'post', data })
}


//开标记录表
export function getOpeningRecords(data) {
  return fetchhj({
    url: `/${prefix}/bid/opening/getOpeningRecords`,
    method: 'post',
    data
  })
}

// 下载开标记录表
export function exportOpenBidRecord(data) {
  return fetchhj({
    url: `/${prefix}/bid/opening/exportOpenBidRecord`,
    method: 'post',
    data
  })
}

//供应商确认
export function confirm(data) {
  return fetch({
    url: `/${prefix}/bid/opening/confirm`,
    method: 'post',
    data
  })
}

//供应商异议列表
export function questionAndAnswerQuery(data) {
  return fetch({
    url: `/${prefix}/bid/opening/questionAndAnswerQuery`,
    method: 'post',
    data
  })
}

//供应商新增异议
export function addQuerstion(data) {
  return fetch({
    url: `/${prefix}/bid/opening/addQuerstion`,
    method: 'post',
    data
  })
}

//查看响应文件
export function queryProviderResponseFileComparison(data) {
  return fetch({
    url: `/czy-busi/bid/quotation/queryProviderResponseFileComparison`,
    method: 'post',
    data
  })
}

//供应商查询未读的消息
export function queryUnreadNum(data) {
  return fetch({
    url: `/czy-project/bid/opening/queryUnreadNum`,
    method: 'post',
    data
  })
}
//供应商查询未读的消息
export function queryByids(data) {
  return fetch({
    url: `/czy-project/bid/opening/queryByids`,
    method: 'get',
    params: data
  })
}
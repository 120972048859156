// CA绑定
import { fetch } from '../../../fetch' // 引入fecth.js
const prefix = 'czy-admin'


export function getIsbandca() {//查询当前供应商是否绑定CA
  return fetch({ url: `/${prefix}/ca/Isbandca`, method: 'post', })
}


export function getKEYIsbang(data) {//根据keyid查询当前CA是否绑定
  return fetch({ url: `/${prefix}/ca/KEYIsbang`, method: 'post', data: JSON.stringify(data), })
}

export function bandcaSave(data) {//绑定CA
    return fetch({ url: `/${prefix}/ca/bandca`, method: 'post', data: JSON.stringify(data), })
}
/**
 * @desc 项目受理及分派 - 经办人分派
 */
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

/**
 * @desc 经办人分派列表
 */
export function getDispatchList(data) {
  return fetch({
    url: `/${prefix}/demand/planDispatchList`,
    method: 'post',
    data
  })
}

/**
 * @desc 经办人分派列表
 */
export function getDispatchDeptList(data) {
  return fetch({
    url: `/${prefix}/demand/dispatchDeptList`,
    method: 'post',
    data
  })
}
/**
 * @desc 经办人分派保存
 */
export function agentSave(data) {
  return fetch({
    url: `/${prefix}/demand/agent/save`,
    method: 'post',
    data
  })
}
import { fetch } from '../../../fetch' // 引入fecth.js
const prefixAdmin = 'czy-admin'
const prefixProject = 'czy-project'
const prefixBusi = 'czy-busi'

// 供应商单点登录验证进入投标/开评标模块(获取用户信息跟token)
export function projectVerify(data) {
    return fetch({ url: `/${prefixAdmin}/singleLogin/provider/projectVerify`, method: 'post', data: JSON.stringify(data), })
}

// 获取参与项目详情
export function getParticipatedProInfo(data) {
    return fetch({ url: `/${prefixProject}/hjClient/getParticipatedProInfo`, method: 'post', data: JSON.stringify(data), })
}

// 获取供应商在线投标信息
export function bidInfo(data) {
    return fetch({ url: `/${prefixProject}/hj/project/bidInfo`, method: 'post', data: JSON.stringify(data), })
}

// 上传投标文件
export function uploadBidFile(data) {
    return fetch({ url: `/${prefixBusi}/hj/provider/uploadBidFile`, method: 'post', data: JSON.stringify(data), })
}

// 撤回投标文件
export function rollback(data) {
    return fetch({ url: `/${prefixBusi}/hj/provider/rollback`, method: 'post', data: JSON.stringify(data), })
}

// 投标记录
export function queryBigRecord(data) {
    return fetch({ url: `/${prefixBusi}/hj/provider/queryBigRecord`, method: 'post', data: JSON.stringify(data), })
}

//获取id
export function getSingupId(data) {
    return fetch({ url: `/${prefixProject}/bid/opening/getSingupId`, method: 'post', data: JSON.stringify(data), })
}

//获取项目信息
export function queryInfo(data) {
    return fetch({ url: `/${prefixBusi}/bid/quotation/bidQuotationProject/query`, method: 'post', data: JSON.stringify(data), })
}

// 需求公式获取参与项目详情
export function xqgsgetParticipatedProInfo(params) {
    return fetch({
        url: `/${prefixProject}/hjClient/getProjectInfo`, method: 'get',
        //  data: JSON.stringify(data), 
        params: params
    })
}

// 供应商单点登录验证进入需求公示模块(获取用户信息跟token)
export function publicProject(data) {
    return fetch({ url: `/${prefixAdmin}/singleLogin/provider/publicProject`, method: 'post', data: JSON.stringify(data), })
}
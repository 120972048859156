
/**
 * @desc 需求公示征集报名情况
 */
import { fetch } from '../../../fetch' // 引入fecth.js
const prefix = '/czy-project'

//需求公示征集报名列表
export function queryDemandPublish(data) {
    return fetch({
        url: `${prefix}/projectSignup/queryDemandPublish`,
        method: 'post',
        data,
    })
}

/**
 * @desc （代理机构）登记审核列表查询
 */
export function getDetail(data) {
    return fetch({
        url: `${prefix}/projectSignup/signupForDljg/detail`,
        method: 'post',
        data,
    })
}

import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'

// 查询需求公式项目延期列表
export function queryList(data) {
  return fetch({
    url: `/${prefix}/biDemandPublicExtension/list/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 需求公式延期保存(修改)/提交/发布
export function saveOrPublish(data) {
  return fetch({
    url: `/${prefix}/biDemandPublicExtension/extension/saveOrPublish`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 需求公式延期详情查询
export function queryExtensionDetail(data) {
  return fetch({
    url: `/${prefix}/biDemandPublicExtension/extensionDetail/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 查询需求公式项目延期审核列表
export function queryAduitList(data) {
  return fetch({
    url: `/${prefix}/biDemandPublicExtension/aduitList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 审核需求公式项目延期
export function aduitExtension(data) {
  return fetch({
    url: `/${prefix}/biDemandPublicExtension/extension/aduit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
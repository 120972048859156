import { fetch } from '../../../fetch' // 引入fecth.js

const prefix = 'czy-busi'
// const prefix = 'crIp'
// 查询列表
export function getList(data) {
  return fetch({
    url: `/${prefix}/evaluateScoreEntity/list`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 详情
export function getInfo(data) {
  return fetch({
    url: `/${prefix}/evaluateScoreEntity/info`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 查询供应商列表
export function getGysList(data) {
  return fetch({
    url: `/${prefix}/evaluateScoreEntity/gysList`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 保存
export function save(data) {
  return fetch({
    url: `/${prefix}/evaluateScoreEntity/save`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 生成评价 测试使用
export function generateEvaluate(params) {
  return fetch({
    url: `/${prefix}/evaluateScoreEntity/generateEvaluate`,
    method: 'get',
    params
  })
}

// 工作台 -  采购人
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

// 项目统计
export function getStatistics(params) {
  return fetch({
    url: `/${prefix}/workbench/statistics`,
    method: 'get',
    params,
  })
}
// 项目列表
export function getProjectList(data) {
  return fetch({
    url: `/${prefix}/workbench/projectList`,
    method: 'post',
    data
  })
}

import {
  fetch
} from '../../../fetch'; // 引入fecth.js

//列表
export function planAgencyAgreementTemplatelist(data) {
  return fetch({
    url: '/czy-busi/planAgencyAgreementTemplate/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//重置
export function reset(data) {
  return fetch({
    url: '/czy-busi/planAgencyAgreementTemplate/reset',
    method: 'post',
    data: JSON.stringify(data)
  });
}
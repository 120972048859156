import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//采购类别数据树
export function tree(params) {
	return fetch({
		url: '/czy-admin/config/item/tree',
		method: 'get',
		params: params
	});
}
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'
const prefix2 = 'czy-project'

// 查询采购附件列表
export function detail(data) {
  return fetch({
    url: `/${prefix}/bif/purchaseAttach/detail`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 采购附件列表保存
export function save(data) {
  return fetch({
    url: `/${prefix}/bif/purchaseAttach/save`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
/**
 * @desc 项目受理及分派 - 经办人分派
 */
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

/**
 * @desc 经办人分派列表
 */
export function getList(data) {
  return fetch({
    url: `/${prefix}/demand/acceptance/query`,
    method: 'post',
    data,
  })
}
/**
 * @desc 部门分派保存
 */
export function deptSave(data) {
  return fetch({
    url: `/${prefix}/demand/dept/save`,
    method: 'post',
    data,
  })
}
/**
 * @desc 需求受理审核（代理机构）
*/
export function auditDemand(data) {
  return fetch({
    url: `/${prefix}/demand/acceptance/audit`,
    method: 'post',
    data,
  })
}

/**
 * @desc 部门分派保存
 */
export function handleDemandBack(data) {
  return fetch({
    url: `/${prefix}/demand/back`,
    method: 'post',
    data,
  })
}



import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//评标列表
export function list(data) {
  return fetch({
    url: '/czy-admin/template/evaluateBid/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//评标模板保存
export function save(data) {
  return fetch({
    url: '/czy-admin/template/evaluateBid/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//评标模板详情
export function detail(data) {
  return fetch({
    url: '/czy-admin/template/evaluateBid/detail',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//删除
export function remove(data) {
  return fetch({
    url: '/czy-admin/template/evaluateBid/remove',
    method: 'post',
    data: JSON.stringify(data)
  });
}
import {
  fetch
} from '../../../fetch'; // 引入fecth.js
// 待报价
export function listToDecrypt(data) {
  return fetch({ url: '/czy-busi/be/conform/multiPrice/listToDecrypt', method: 'post', data: JSON.stringify(data) });
}
// 已报价
export function listDecrypted(data) {
  return fetch({ url: '/czy-busi/be/conform/multiPrice/listDecrypted', method: 'post', data: JSON.stringify(data) });
}
// 已报价
export function detail(data) {
  return fetch({ url: '/czy-busi/be/conform/multiPrice/detail', method: 'post', data: JSON.stringify(data) });
}
// 已报价
export function save(data) {
  return fetch({ url: '/czy-busi/be/conform/multiPrice/save', method: 'post', data: JSON.stringify(data) });
}

// 报价详情
export function providerDetail(data) {
  return fetch({ url: '/czy-busi/hj/provider/secondQuotation/detail', method: 'post', data: JSON.stringify(data) });
}

// 报价-投标文件上传提交
export function providerUpload(data) {
  return fetch({ url: '/czy-busi/hj/provider/secondQuotation/upload', method: 'post', data: JSON.stringify(data) });
}

// 二次报价加密密钥
export function encryptedKey(data) {
  return fetch({ url: '/czy-busi/hj/provider/secondQuotation/encryptedKey', method: 'post', data: JSON.stringify(data) });
}

// 二次报价解密
export function providerDecrypt(data) {
  return fetch({ url: '/czy-busi/hj/provider/secondQuotation/decrypt', method: 'post', data: JSON.stringify(data) });
}

// 报价详情
export function providerDetailinteavel(data) {
  return fetch({ url: '/czy-busi/hj/provider/secondQuotation/detail', method: 'post', data: JSON.stringify(data), }, false);
}
import { fetch } from '../../../fetch' // 引入fecth.js

const prefix2 = 'czy-busi'
// const prefix2 = 'crIp'
// 有评分框架协议
export function scoreFrame(data) {
  return fetch({
    url: `/${prefix2}/reviewResult/scoreFrame`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 有评分非框架协议
export function scoreNoFrame(data) {
  return fetch({
    url: `/${prefix2}/reviewResult/scoreNoFrame`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 无评分框架协议
export function noScoreFrame(data) {
  return fetch({
    url: `/${prefix2}/reviewResult/noScoreFrame`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 无评分非框架协议
export function noScoreNoFrame(data) {
  return fetch({
    url: `/${prefix2}/reviewResult/noScoreNoFrame`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 保存其他情况
export function saveOtherCases(data) {
  return fetch({
    url: `/${prefix2}/reviewResult/saveOtherCases`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 保存中标候选人变更
export function saveAlter(data) {
  return fetch({
    url: `/${prefix2}/reviewResult/saveAlter`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 评审结束
export function finishReviewResult(data) {
  return fetch({
    url: `/${prefix2}/reviewResult/finish`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 获取表清单
export function getTables(data) {
  return fetch({
    url: `/${prefix2}/reviewResult/getTables`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 生成文件
export function generateFile(data) {
  return fetch({
    url: `/${prefix2}/reviewResult/generateFile`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 导出明细分
export function derivedDetail(data) {
  return fetch({
    url: `/${prefix2}/reviewResult/derivedDetail`,
    method: 'post',
    data: JSON.stringify(data),
    // responseType: "blob",
  })
}

// 查看全部
export function viewAll(data) {
  return fetch({
    url: `/${prefix2}/reviewResult/viewAll`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 转采购人确认结果
export function transferPurchaserConfirms(data) {
  return fetch({
    url: `/${prefix2}/reviewResult/transferPurchaserConfirms`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 查询可以正常进入评审（评审之前未被废除）的包号列表
export function queryBaohaos(data) {
  return fetch({ url: `/${prefix2}/bid/evaluate/normalBaohaos/query`, method: 'post', data: JSON.stringify(data), })
}

//获取项目的所有包
export function queryBaohaosAll(data) {
  return fetch({ url: `/czy-project/projectInfo/listBaohaos`, method: 'post', data: JSON.stringify(data), })
}

//专家评审结束异步任务请求
export function finishStatus(params) {
  return fetch({ url: `/czy-busi/reviewResult/finishStatus`, method: 'get', params: params, }, false)
}

// 废包转采购人确认结果
export function transferFailsConfirm(data) {
  return fetch({
    url: `/${prefix2}/reviewResult/transferFailsConfirm`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 供应商询标接口
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'
// const prefix2 = 'ylrIp'
const prefix2 = 'czy-project'
/**
 * @desc 供应商询标列表
 */
export function queryDocumentReview(data) {
  return fetch({
    url: `/${prefix}/biDmand/documentReview/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
/**
 * @desc 供应商询标列表
 */
export function queryDataReviewList(data) {
  return fetch({
    url: `/${prefix}/biDmand/dataReviewList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
/**
 * @desc 供应商询标列表
 */
export function getRegisteredMaterial(data) {
  return fetch({
    url: `/${prefix2}/projectSignup/examineList/select`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 供应商询标列表
 */
export function getRegisteredMaterialselectByOrg(data) {
  return fetch({
    url: `/${prefix2}/projectSignup/examineList/selectByOrg`,
    method: 'post',
    data: JSON.stringify(data),
  })
}



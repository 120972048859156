// 采购人/代理机构 - 框架协议
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'
// const prefix2 = 'ylrIp'
const prefix2 = 'czy-project'

/**
 * @desc 查询框架协议签订待提交/已提交列表
 */
export function getList(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/signingList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 框架协议签订提交
export function commit(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/signing/commit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 查询框架协议签订详情信息
export function queryDetail(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/signingDetail/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 采购人/代理机构 保存截止日期
export function saveEndTime(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/signingEndTime/save`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 查看委托协议附件信息
export function queryAgent(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/commissionAgentAnnex/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 看委托代理商-委托代理商列表信息 todo 供应商委托代理商
export function queryAgentList(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/commissionAgentList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 委托代理商模板下载
export function kjTemplateDownLoad() {
  return fetch({ url: `/${prefix}/frameworkAgreementSigning/kjTemplate/downLoad`, method: 'get', responseType: 'blob', });
}

// 查询供应商-框架协议签订待提交/已提交列表
export function queryProviderList(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/signingListForProvider/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 供应商-查询框架协议签订详情信息
export function queryProviderDetail(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/signingDetailForProvider/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 供应商-框架协议签订提交
export function saveOrCommitProvider(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/signingForProvider/saveOrCommit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
/**
 * @desc 查询项目状态字典信息
 */
export function getStatusList(data) {
  return fetch({
    url: `/${prefix2}/sysStatusEntity/projectStatus/list`,
    method: 'post',
    data,
  })
}

// 供应商-点击框架协议签订按钮
export function ProviderStamp(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/signingForProvider/stamp`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 供应商-盖章操作前调用
export function checkCompleted(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/signingStatusIsCompleted/check`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 采购人/代理机构-框架协议签订盖章
export function cgrStamp(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/signing/stamp`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
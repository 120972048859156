/**
 * @desc  委托协议签订 接口方法文件
 */
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

/**
 * @desc 采购人需要委托列表
 */
export function getDemandList(data = {}) {
  return fetch({
    url: `/${prefix}/demand/planDemandList`,
    method: 'post',
    data,
  })
}

/**
 * @desc 需求委托详情查询
 */
export function getDemandInfo(data = {}) {
  return fetch({
    url: `/${prefix}/demand/info`,
    method: 'post',
    data,
  })
}

/**
 * @desc 需求委托详情保存/提交(采购人)
 */
export function saveDemand(data = {}) {
  return fetch({
    url: `/${prefix}/demand/save`,
    method: 'post',
    data,
  })
}

/**
 * @desc 供应商资格要求查询
 */
export function getSupplierDemand(data = {}) {
  return fetch({
    url: `/${prefix}/demand/supplierQualification/query`,
    method: 'post',
    data,
  })
}

/**
 * @desc
 *
 */
export function getConfig(params) {
  return fetch({
    url: `/${prefix}/demand/getConfig`,
    method: 'get',
    params,
  })
}


/**
 * @desc 获取状态值
*/
export function getStates(params) {
  return fetch({
    url: `/${prefix}/planAgencyAgreement/getStates`,
    method: 'get',
    params,
  })
}

/**
 * @desc 生成项目授权委托书
*/
export function generateProjectPowerAttorney(data = {}) {
  return fetch({
    url: `/${prefix}/demand/generateProjectPowerAttorney`,
    method: 'post',
    data,
  })
}

/**
 * @desc 计量单位查询
*/
export function queryUnitMeasurement(params) {
  return fetch({
    url: `/${prefix}/demand/unitMeasurement/query`,
    method: 'get',
    params
  })
}

/**
 * @desc 获取生成项目授权委托书的信息 传planId
*/
export function getProjectPowerAttorneyInfo(data) {
  return fetch({
    url: `/${prefix}/demand/getProjectPowerAttorneyInfo`,
    method: 'post',
    data
  })
}
/**
 * @desc 获取生成项目授权委托书的信息 传planId
*/
export function getTemplate(params) {
  return fetch({
    url: `/${prefix}/demand/getTemplate`,
    method: 'get',
    params,
    responseType: 'blob'
  })
}

/**
 * @desc 获取项目授权委托书文件信息
*/
export function getPdfInfo(data) {
  return fetch({
    url: `/${prefix}/demand/getPdfInfo`,
    method: 'post',
    data
  })
}

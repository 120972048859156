// 供应商询标接口
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

/**
 * @desc 供应商询标列表
 */
export function getList(data) {
  return fetch({
    url: `/${prefix}/bid/clear/list`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 供应商询标应答页面
*/
export function clearLookPage(data) {
  return fetch({
    url: `/${prefix}/bid/clear/look`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
/**
 * @desc 供应商询标应答页面回复提交
*/
export function saveBySupperId(data) {
  return fetch({
    url: `/${prefix}/bid/clear/saveBySupperId`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

export function getSupplierClear(params) {
    return fetch({
      url: `/${prefix}/bid/clear/lookSupplierId`,
      method: 'get',
      params,
    })
  }
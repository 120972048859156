import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//列表
export function list(data) {
  return fetch({
    url: '/czy-busi/reevaluate/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//重评申请弹框表格
export function applyForList(data) {
  return fetch({
    url: '/czy-busi/reevaluate/applyFor/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//重评申请弹框表格确认
export function applyForSave(data) {
  return fetch({
    url: '/czy-busi/reevaluate/applyFor/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//详情保存和提交
export function save(data) {
  return fetch({
    url: '/czy-busi/reevaluate/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//开启评标室
export function openRoom(data) {
  return fetch({
    url: '/czy-busi/reevaluate/openRoom',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//详情
export function info(data) {
  return fetch({
    url: '/czy-busi/reevaluate/info',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//审核列表
export function auditList(data) {
  return fetch({
    url: '/czy-busi/reevaluate/auditList',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//审核
export function audit(data) {
  return fetch({
    url: '/czy-busi/reevaluate/audit',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//专家重评列表
export function reviewList(data) {
  return fetch({
    url: '/czy-busi/reevaluate/reviewList',
    method: 'post',
    data: JSON.stringify(data)
  });
}

/*
 * aioxs 接口请求函数
 */
import { fetch } from '../fetch'; // 引入fecth.js
import api from '../url'; // 引入url.js
// import qs from 'qs'; // 引入qs

/*
 * /components/common/topHeader/topHeader
 * *********************************************************************************************************************
 */
// 退出登录
export function removeToken(params) {
	return fetch({
		url: api.removeToken,
		method: 'get',
		params: params
	});
}

// 监管 -  评价管理
import { fetch } from '../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

// 查询列表
export function queryList(data) {
  return fetch({
    url: `/${prefix}/superviseEvaluate/list`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 详情
export function queryInfo(data) {
  return fetch({
    url: `/${prefix}/superviseEvaluate/info`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

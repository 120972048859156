// 菜单管理
import { fetch } from '../../../fetch' // 引入fecth.js
const prefix = 'czy-admin'
const prefixOther = 'wuhan'

export function sysMenu() {// 菜单查询
    return fetch({ url: `/${prefix}/sysMenu/tree`, method: 'get', data: {}, })
}

export function getSaveOrUpdate(data) {// 菜单新增/修改
    return fetch({ url: `/${prefix}/sysMenu/saveOrUpdate`, method: 'post', data: JSON.stringify(data), })
}

export function sysMenuDelete(data) {// 菜单删除
    return fetch({ url: `/${prefix}/sysMenu/delete?id=${data}`, method: 'get', data: JSON.stringify(data), })
}


// 主体菜单管理
export function areaTree() {//区划树区级-查询(areaId查询)
    return fetch({ url: `/${prefix}/sysAdminArea/areaTree`, method: 'get', data: {}, })
}

export function areatreeByCode( roleType) {//区划树区级-查询(areaCode查询)
    return fetch({ url: `/${prefix}/sysMenu/areaMenu/treeByCode?roleType=${roleType}`, method: 'get', data: {}, })
}

export function sysAreaMenu(data) {//列表查询
    return fetch({ url: `/${prefix}/sysMenu/areaMenu/pageList`, method: 'post', data: JSON.stringify(data), })
}

export function sysMenuTree(areaId, roleType) {// 区划主体菜单-查询菜单树
    return fetch({ url: `/${prefix}/sysMenu/areaMenu/tree?areaId=${areaId}&roleType=${roleType}`, method: 'get', data: {}, })
}

export function areaMenuSave(areaId, menuIds, roleType) {// 区划主体菜单-保存
    return fetch({ url: `/${prefix}/sysMenu/areaMenu/save?areaId=${areaId}&menuIds=${menuIds}&roleType=${roleType}`, method: 'get', data: {}, })
}

export function areaMenuCopy(areaId, copyAreaIds) {// 区划主体菜单-复制
    return fetch({ url: `/${prefix}/sysMenu/areaMenu/copy?areaId=${areaId}&copyAreaIds=${copyAreaIds}`, method: 'get', data: {}, })
}


//角色管理
export function sysRoleQuery(data) {//列表查询
    return fetch({ url: `/${prefix}/role/list`, method: 'post', data: JSON.stringify(data), })
}

export function sysRoleSave(data) {//角色新增/修改
    return fetch({ url: `/${prefix}/role/save`, method: 'post', data: JSON.stringify(data), })
}

export function sysRoleDel(data) {//角色删除
    return fetch({ url: `/${prefix}/role/delete`, method: 'post', data: data })
}

export function saveMenu(roleNo, menuIds) {// 角色菜单资源保存
    return fetch({ url: `/${prefix}/role/saveMenu?roleNo=${roleNo}`, method: 'post', data: menuIds, })
}

export function sysRoleCopy(data) {//角色资源复制
    return fetch({ url: `/${prefix}/role/copy`, method: 'post', data: JSON.stringify(data), })
}


//部门管理
export function sysDeptQuery(data) {//列表查询
    return fetch({ url: `/${prefix}/department/list`, method: 'post', data: JSON.stringify(data), })
}

export function sysDeptSave(data) {//部门新增/修改
    return fetch({ url: `/${prefix}/department/save`, method: 'post', data: JSON.stringify(data), })
}

export function sysDeptDel(data) {//部门删除
    return fetch({ url: `/${prefix}/department/delete`, method: 'post', data: data })
}

export function sysDeptCopy(data) {//部门复制
    return fetch({ url: `/${prefix}/department/copy`, method: 'post', data: JSON.stringify(data), })
}


//用户管理
export function sysUserQuery(data) {//列表查询
    return fetch({ url: `/${prefix}/user/list`, method: 'post', data: JSON.stringify(data), })
}

export function sysUserSave(data) {//用户新增/修改
    return fetch({ url: `/${prefix}/user/save`, method: 'post', data: JSON.stringify(data), })
}

export function sysviewRole(operatorNo) {//查看用户拥有的角色
    return fetch({ url: `/${prefix}/user/viewRole?operatorNo=${operatorNo}`, method: 'get', data: {}, })
}

export function sysUserSaveRole(operatorNo, roleIds) {//保存用户角色
    return fetch({ url: `/${prefix}/user/saveRole?operatorNo=${operatorNo}`, method: 'post', data: roleIds, })
}
// 专家录入
export function sysExpertQuery(data) {//列表查询
    return fetch({ url: `/${prefix}/user/expert/page`, method: 'post', data:JSON.stringify(data) , })
}

export function sysExpertAdd(data) {//新增
    return fetch({ url: `/${prefix}/api/expert/add`,method: 'post', data:JSON.stringify(data) , })
}

export function sysExpertUpdate(data) {//编辑专家
    return fetch({ url: `/${prefix}/api/expert/update`,method: 'post', data:JSON.stringify(data) , })
}
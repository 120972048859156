import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//列表
export function list(data) {
  return fetch({
    url: '/czy-busi/project/question/reply/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//质疑保存
export function questionReply(data) {
  return fetch({
    url: '/czy-busi/project/question/reply',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//质疑回复函
export function generateReplyLetter(params) {
  return fetch({
    url: '/czy-busi/project/question/generateReplyLetter',
    method: 'get',
    params: params
  });
}
//质疑审核 
export function audit(data) {
  return fetch({
    url: '/czy-busi/project/question/audit',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//质疑不受理
export function reject(data) {
  return fetch({
    url: '/czy-busi/project/question/reject',
    method: 'post',
    data: JSON.stringify(data)
  });
}
/*
 * aioxs 接口请求函数
 */
import { fetch } from '../fetch'; // 引入fecth.js
import api from '../url'; // 引入url.js
import qs from 'qs'; // 引入qs

/*
 * /views/main/login 登录
 * *********************************************************************************************************************
 */
// 获取所有区划
export function getTenantList(params) {
    return fetch({
        url: api.getTenantList,
        method: 'get',
        params: params
    });
}

//获取登录密码加密的密钥
export function getPublicKey() {
    return fetch({ url: api.getPublicKey, method: 'get', });
}

export function oauthToken(data) {// 登录
    return fetch({ url: api.oauthToken, method: 'post', headers: { 'Content-Type': 'application/json', 'Authorization': 'Basic enRid2ViOjEyMw==', }, data: data });
}

export function loginSmsVerificationCode(data) {//获取忘记密码的验证码
    return fetch({ url: api.loginSmsVerificationCode, method: 'post', data: JSON.stringify(data) });
}

export function loginContrastSmsVerificationCode(data) {//重置密码
    return fetch({ url: api.loginContrastSmsVerificationCode, method: 'post', data: JSON.stringify(data) });
}

export function resetPwd(data) {//修改密码
    return fetch({ url: api.resetPwd, method: 'post', data: JSON.stringify(data) });
}

export function verifySignedData(data) {//CA登录验签
    return fetch({ url: api.verifySignedData, method: 'post', data: JSON.stringify(data) });
}

export function calogin(data) {//CA登录
    return fetch({ url: api.calogin, method: 'post', data: JSON.stringify(data) });
}

export function applyQrCode(data) {//标信通登录方式获取对应的二维码
    return fetch({ url: api.applyQrCode, method: 'post', data: JSON.stringify(data) });
}

export function qRLogin(data) {//循环获取二维码的扫码状态
    return fetch({ url: api.qRLogin, method: 'post', data: JSON.stringify(data) }, false);
}

export function smLoginValidation(data) {//扫码授权后验证
    return fetch({ url: api.smLoginValidation, method: 'post', data: JSON.stringify(data) });
}





/*
 * /views/main/register 注册
 * *********************************************************************************************************************
 */


export function GetCode() {//供应商-获取电子营业执照
    return fetch({ url: api.getCode, method: 'get', data: {} });
}
export function getImageFromEhb(data) {//供应商-获取电子营业执照
    return fetch({ url: api.getImageFromEhb, method: 'post', data: JSON.stringify(data) });
}

export function countByName(data) {//供应商-校验企业名称是否重复
    return fetch({ url: api.countByName, method: 'post', data: JSON.stringify(data) });
}

export function providerRegSubmit(data) {//供应商-注册提交
    return fetch({ url: api.providerRegSubmit, method: 'post', data: JSON.stringify(data) });
}




// 获取主体类型Map-所有
export function getBusinessPlatforms(params) {
    return fetch({ url: api.getBusinessPlatforms, method: 'get', params: params });
}

// 注册主体（账户）
export function addEntity(data) {
    return fetch({ url: api.addEntity, method: 'post', data: JSON.stringify(data) });
}

// ossid获取文件
export function filedownload(data) {
    return fetch({
        url: '/czy-file/file/download',
        method: 'get',
        responseType: 'blob',
        // data: JSON.stringify(data),
        params: data
    });
}

// ossid获取文件地址
export function getossDataurl(data) {
    return fetch({
        url: '/czy-file/file/ossData',
        method: 'get',
        // responseType: 'blob',
        // data: JSON.stringify(data),
        params: data
    });
}
/**
 * @desc 专家申请
 */
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'

/**
 * @desc 专家申请 保存/修改/提交
 */
export function getList(data) {
  return fetch({
    url: `/${prefix}/projectExpertApplication/expertApplicationList/query`,
    method: 'post',
    data,
  })
}
/**
 * @desc 专家申请 详情查询
 */
export function queryDetail(data) {
  return fetch({
    url: `/${prefix}/projectExpertApplication/expertApplicationDetail/query`,
    method: 'post',
    data,
  })
}
/**
 * @desc 专家申请 保存/修改/提交
 */
export function saveOrCommit(data) {
  return fetch({
    url: `/${prefix}/projectExpertApplication/expertApplication/saveOrCommit`,
    method: 'post',
    data,
  })
}

/**
 * @desc 专家申请-审核函书
*/
export function aduitLetter(data) {
  return fetch({
    url: `/${prefix}/projectExpertApplication/expertApplication/aduitAuthorizationLetter`,
    method: 'post',
    data,
  })
}
/**
 * @desc 专家申请 撤回
 */

export function withdrawal(data) {
  return fetch({
    url: `/${prefix}/projectExpertApplication/expertApplication/withdrawal`,
    method: 'post',
    data,
  })
}

/**
 * @desc 专家申请 待/已审核 列表查询
 */

export function queryExamineList(data) {
  return fetch({
    url: `/${prefix}/projectExpertApplication/expertApplicationExamine/list`,
    method: 'post',
    data,
  })
}
/**
 * @desc 专家申请 审核
 */

export function applyExamine(data) {
  return fetch({
    url: `/${prefix}/projectExpertApplication/expertApplication/examine`,
    method: 'post',
    data,
  })
}

/**
 * @desc 专家申请审核函书-查询函书详情
 */

export function queryAuthorizationLetter(data) {
  return fetch({
    url: `/${prefix}/projectExpertApplication/expertApplication/queryAuthorizationLetter`,
    method: 'post',
    data,
  })
}

/**
 * @desc 专家申请-查询函书详情
 */

export function queryAuthorizationLetterDetail(data) {
  return fetch({
    url: `/${prefix}/projectExpertApplication/expertApplication/queryAuthorizationLetterDetail`,
    method: 'post',
    data,
  })
}

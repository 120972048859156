import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//列表
export function list(data) {
  return fetch({
    url: '/czy-admin/config/sensitiveWord/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function save(data) {
  return fetch({
    url: '/czy-admin/config/sensitiveWord/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function remove(data) {
  return fetch({
    url: '/czy-admin/config/sensitiveWord/remove',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 电子归档
import { fetch } from '../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'

// 查询列表
export function queryList(data) {
  return fetch({
    url: `/${prefix}/electronicFilling/electronicFillingList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 电子归档详情查询
export function queryDetail(data) {
  return fetch({
    url: `/${prefix}/electronicFilling/electronicFillingDetail/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 电子归档详情查询
export function saveOrCommit(data) {
  return fetch({
    url: `/${prefix}/electronicFilling/electronicFilling/saveOrCommit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 框架协议文件信息更新
export function updateAgreement(data) {
  return fetch({
    url: `/${prefix}/electronicFilling/frameworkAgreementAnnex/update`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 电子归档-撤回
export function withdrawElectronic(data) {
  return fetch({
    url: `/${prefix}/electronicFilling/electronicFilling/withdraw`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 一键下载
*/
export function downloadAll(data) {
  return fetch({
    url: `/${prefix}/electronicFilling/electronicFilling/downloadAll`,
    method: 'post',
    data: JSON.stringify(data),
    responseType: 'blob'
  })
}



/**
 * @desc 结果公告（采购中心）
 */
import { fetch } from '../../../fetch' // 引入fecth.js


// const prefix =  'ylr8080'
const prefix =  'czy-busi'
/**
 * @desc （采购中心）结果公告待审核/已审核列表查询 ( 待审核页面操作栏显示 "审核" 已审核显示"查看")
 */
export function getList(data) {
  return fetch({
    url: `/${prefix}/projectNoticeInfoEntity/noticeExamine/list`,
    method: 'post',
    data,
  })
}

/**
 * @desc 结果公告审核
*/
export function examineNotice(data) {
  return fetch({
    url: `/${prefix}/projectNoticeInfoEntity/examine/resultNotice`,
    method: 'post',
    data,
  })
}

/**
 * @desc 终止公告审核
*/
export function examineEndNotice(data) {
  return fetch({
    url: `/${prefix}/projectNoticeInfoEntity/examine/endNotice`,
    method: 'post',
    data,
  })
}
/**
 * @desc 采购项目管理
 */
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylrIp'
const prefix = '/czy-project'

/**
 * @desc （代理机构）登记审核列表查询
 */
export function getList(data) {
  return fetch({
    url: `${prefix}/projectSignup/signupForDljg/list`,
    method: 'post',
    data,
  })
}

/**
 * @desc （代理机构）登记审核列表查询
 */
export function getDetail(data) {
  return fetch({
    url: `${prefix}/projectSignup/signupForDljg/detail`,
    method: 'post',
    data,
  })
}

/**
 * @desc （代理机构）已完成查看列表
 */
export function detailForList(data) {
  return fetch({
    url: `${prefix}/projectSignup/signupForDljg/detailForList`,
    method: 'post',
    data,
  })
}

/**
 * @desc 参与投标 - 项目登记
 */
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'
/**
 * @desc （供应商）项目待/已登记列表查询
 */
export function getList(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/bidNoticeList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
/**
 * @desc  查询框架协议签订情况
 */
export function queryProjectInfo(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/signingInfoList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
/**
 * @desc  查询入围公告详情
 */
export function queryBidNoticeDetail(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/bidNoticeDetail/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
/**
 * @desc  入围公告保存/发布
 */
export function saveOrRelease(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/bidNotice/saveOrRelease`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc  框架协议结束签订
 */
export function endSigning(data) {
  return fetch({
    url: `/${prefix}/frameworkAgreementSigning/signing/end`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 采购文件需求沟通
 */
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

/**
 * @desc 项目需求沟通列表
 */
export function getList(data) {
  return fetch({
    url: `/${prefix}/project/demandCommunicate/list`,
    method: 'post',
    data,
  })
}

/**
 * @desc 项目需求沟通保存
 */
export function saveDemand(data) {
  return fetch({
    url: `/${prefix}/project/demandCommunicate/input`,
    method: 'post',
    data,
  })
}
/**
 * @desc 项目需求沟通保存
 */
export function getInfolist(data) {
  return fetch({
    url: `/${prefix}/project/demandCommunicate/infolist`,
    method: 'post',
    data,
  })
}

// 项目检索
import { fetch } from '../../../fetch' // 引入fecth.js

const prefix2 = 'czy-busi'
// const prefix2 = 'ylr8080'
// 项目检索列表查询
export function queryProjectSearchList(data) {
  return fetch({
    url: `/${prefix2}/projectSearch/projectSearchList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 项目检索列表查询
export function exportList(data) {
  return fetch({
    url: `/${prefix2}/projectSearch/projectSearchList/export`,
    method: 'post',
    data: JSON.stringify(data),
    responseType: 'blob'
  })
}

// 专家参与分析-列表查询
export function queryExportList(data) {
  return fetch({
    url: `/${prefix2}/projectSearch/expertJoinAnalysisList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 专家参与分析-列表查询
export function queryDetailList(data) {
  return fetch({
    url: `/${prefix2}/projectSearch/expertJoinAnalysisDetailList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 人员计划进度统计查询
export function queryPlanProgressStatistics(data) {
  return fetch({
    url: `/${prefix2}/projectSearch/planProgressStatistics/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 采购类型进度统计查询
export function queryProcurementTypeProgressStatistics(data) {
  return fetch({
    // url: `/${prefix2}/projectSearch/procurementTypeProgressStatistics/query`,
    url: `/${prefix2}/projectSearch/procurementTypeProgressStatistics/queryNew`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 进度统计-查询弹窗（通用）
export function queryProgressStatisticsDetailList(data) {
  return fetch({
    url: `/${prefix2}/projectSearch/progressStatisticsDetailList/queryNew`,
    method: 'post',
    data: JSON.stringify(data),
  })
}



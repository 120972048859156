/**
 * @desc 委托采购 - 计划录入
 */
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylrIp'
const prefix = 'czy-project'

export default {
  /**
   * @desc 获取计划列表
   */
  getList: (data = {}) => {
    return fetch({
      url: `/${prefix}/planInfo/plan/list`,
      method: 'post',
      data: JSON.stringify(data),
    })
  },
  /**
   * @desc 计划录入/修改
   */
  adjustPlan: (data = {}) => {
    return fetch({
      url: `/${prefix}/planInfo/create/planInfo`,
      method: 'post',
      data: JSON.stringify(data),
    })
  },
  /**
   * @desc 计划删除
   */
  deletePlan: (params = {}) => {
    return fetch({
      url: `/${prefix}/planInfo/delete/planInfo`,
      method: 'get',
      params,
    })
  },
  /**
   * @desc 代理机构下拉查询
   */
  queryAgent(orgType) {
    return fetch({ url: `/czy-admin/dictOrganizeInfo/agencyInfoList/query?orgType=${orgType}`, method: 'get', data: {}, })
  },

}

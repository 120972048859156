import { fetch } from '../../../fetch'; // 引入fecth.js
//列表
export function list(data) {
  return fetch({
    url: '/czy-busi/statistics/supplier/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 导出
export function listExport(data) {
  return fetch({
    url: '/czy-busi/statistics/supplier/list/export',
    responseType: 'blob',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//查看
export function look(data) {
  return fetch({
    url: '/czy-busi/statistics/supplier/look',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// <!--- 评委授权函--->
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'

/**
 * @desc 评委授权函 列表查询
 */
export function queryList(data) {
  return fetch({
    url: `/${prefix}/authorizationLetterInfoEntity/authorizationLetterList/query`,
    method: 'post',
    data,
  })
}

/**
 * @desc 评委授权函 详情查询
 */
export function queryDetail(data) {
  return fetch({
    url: `/${prefix}/authorizationLetterInfoEntity/authorizationLetterDetail/query`,
    method: 'post',
    data,
  })
}
/**
 * @desc 评委授权函 保存
 */
export function save(data) {
  return fetch({
    url: `/${prefix}/authorizationLetterInfoEntity/authorizationLetter/save`,
    method: 'post',
    data,
  })
}
/**
 * @desc 评委授权函提交
 */
export function commit(data) {
  return fetch({
    url: `/${prefix}/authorizationLetterInfoEntity/authorizationLetter/commit`,
    method: 'post',
    data,
  })
}
/**
 * @desc 评委授权函提交
 */
export function withdraw(data) {
  return fetch({
    url: `/${prefix}/authorizationLetterInfoEntity/authorizationLetter/withdraw`,
    method: 'post',
    data,
  })
}
/**
 * @desc 修改评委说明/授权盖章状态为已盖章 (盖章成功后调用)
 */
export function updateStampStatus(data) {
  return fetch({
    url: `/${prefix}/authorizationLetterInfoEntity/authorizationLetterStampStatus/update`,
    method: 'post',
    data,
  })
}
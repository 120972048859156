// 供应商询标接口
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

/**
 * @desc 供应商询标列表
 */
export function getList(data) {
  return fetch({
    url: `/${prefix}/bid/inquiry/supplierInquiry/list`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 供应商询标应答页面
*/
export function answerPage(data) {
  return fetch({
    url: `/${prefix}/bid/inquiry/supplierInquiry/answerPage`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
/**
 * @desc 供应商询标应答页面回复提交
*/
export function answerSubmit(data) {
  return fetch({
    url: `/${prefix}/bid/inquiry/supplierInquiry/answerSubmit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
//供应商询标应答页面确认询标文件
export function qrSubmit(data) {
  return fetch({
    url: `/${prefix}/bid/inquiry/supplierInquiry/qrSubmit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//列表
export function list(data) {
  return fetch({
    url: '/czy-flow/auditingFlowInfo/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function sumInfo(data) {
  return fetch({
    url: '/czy-flow/auditingFlowInfo/sumInfo',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function save(data) {
  return fetch({
    url: '/czy-flow/auditingFlowInfo/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function remove(data) {
  return fetch({
    url: '/czy-flow/auditingFlowInfo/remove',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function toggleStatus(data) {
  return fetch({
    url: '/czy-flow/auditingFlowInfo/toggleStatus',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function listOperator(data) {
  return fetch({
    url: '/czy-flow/auditingFlowInfo/listOperator',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function listResource(params) {
	return fetch({
		url: '/czy-flow/auditingFlowInfo/listResource',
		method: 'get',
		params: params
	});
}
export function saveChild(data) {
  return fetch({
    url: '/czy-flow/auditingFlowInfo/saveChild',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function listDept(data) {
  return fetch({
    url: '/czy-flow/auditingFlowInfo/listDept',
    method: 'post',
    data: JSON.stringify(data)
  });
}
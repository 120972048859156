// 需求公示废包公告
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'

// 查询待制作/已制作列表
export function queryMakeList(data) {
  return fetch({
    url: `/${prefix}/wasteProcurementResult/makeList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 查询新增废标公告弹框列表数据
export function queryProjectList(data) {
  return fetch({
    url: `/${prefix}/wasteProcurementResult/projectList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 新增废标公告数据
export function addBid(data) {
  return fetch({
    url: `/${prefix}/wasteProcurementResult/projectList/save`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 查询废标采购结果详情数据
export function queryDetail(data) {
  return fetch({
    url: `/${prefix}/wasteProcurementResult/wasteProcurementResultDetail/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 废标结果保存(修改)/提交
export function saveOrCommit(data) {
  return fetch({
    url: `/${prefix}/wasteProcurementResult/result/saveOrCommit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 查询废标采购结果审核列表
export function queryAduitList(data) {
  return fetch({
    url: `/${prefix}/wasteProcurementResult/wasteProcurementResultAduitList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 审核废标采购结果
export function aduitResult(data) {
  return fetch({
    url: `/${prefix}/wasteProcurementResult/wasteProcurementResult/aduit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 生成（查询）终止公告
export function queryWasteNotice(data) {
  return fetch({
    url: `/${prefix}/wasteProcurementResult/wasteNotice/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 保存/发布废包公告
export function saveOrPublish(data) {
  return fetch({
    url: `/${prefix}/wasteProcurementResult/wasteNotice/saveOrPublish`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 删除废包公告
export function remove(data) {
  return fetch({
    url: `/${prefix}/wasteProcurementResult/projectList/remove`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

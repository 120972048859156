import { fetch } from '../../../fetch'; // 引入fecth.js
//列表
export function list(data) {
  return fetch({
    url: '/czy-busi/statistics/dataReporting/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 导出
export function listExport(data) {
  return fetch({
    url: '/czy-busi/statistics/dataReporting/list/export',
    method: 'post',
    responseType: 'blob',
    data: JSON.stringify(data)
  });
}
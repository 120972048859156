import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//待确认
export function cgrQualificationList(data) {
  return fetch({
    url: '/czy-project/api/project/projectInfo/cgrQualificationList',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 征集--采购文件确定
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

/**
 * @desc 列表
 */
export function queryList(data) {
  return fetch({
    url: `/${prefix}/collect/supplier/review/list`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 签到及回避页面信息
 */
export function signInAvoid(data) {
  return fetch({
    url: `/${prefix}/collect/supplier/review/signInAvoid`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 推荐组长页面信息
 */
export function RecommendedGroupLeader(data) {
  return fetch({
    url: `/${prefix}/collect/supplier/review/RecommendedGroupLeader`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 资料审查页面获取列表信息
 */
export function getDataReviewList(data) {
  return fetch({
    url: `/${prefix}/collect/supplier/review/dataReview/list`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 资料审查页面 审查查看
 */
export function lookDataReview(data) {
  return fetch({
    url: `/${prefix}/collect/supplier/review/dataReview/look`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 资料审查页面 审查结果提交
 */
export function lookSubmitDataReview(data) {
  return fetch({
    url: `/${prefix}/collect/supplier/review/dataReview/lookSubmit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 资料审查页面保存
 */
export function saveDataReview(data) {
  return fetch({
    url: `/${prefix}/collect/supplier/review/dataReview/save`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 资料审查页面一键通过
 */
export function onePassDataReview(data) {
  return fetch({
    url: `/${prefix}/collect/supplier/review/dataReview/onePass`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 评审结果页面获取审查列表信息
 */
export function getReviewResultList(data) {
  return fetch({
    url: `/${prefix}/collect/supplier/review/reviewResult/list`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 评审结果保存
 */
export function saveReviewResult(data) {
  return fetch({
    url: `/${prefix}/collect/supplier/review/reviewResult/save`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 评审结果 本包废标
 */
export function rejectedBid(data) {
  return fetch({
    url: `/${prefix}/collect/supplier/review/reviewResult/rejectedBid`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 获取审查未完成剩余人数
 */
export function noCompletePeopleNum(data) {
  return fetch({
    url: `/${prefix}/collect/supplier/review/reviewResult/noCompletePeopleNum`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
/**
 * @desc 获取征集采购文件
*/
export function getDocument(data) {
  return fetch({
    url: `/${prefix}/collect/supplier/review/reviewResult/purchasingDocument`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

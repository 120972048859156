import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//待审核
export function listToApproveDmandPub(data) {
  return fetch({
    url: '/czy-project/projectInfo/dmandPub/listToApproveDmandPub',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//已审核
export function listApprovedDmandPub(data) {
  return fetch({
    url: '/czy-project/projectInfo/dmandPub/listApprovedDmandPub',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//需求公示审核
export function approve(data) {
  return fetch({
    url: '/czy-project/projectInfo/approve',
    method: 'post',
    data: JSON.stringify(data)
  });
}
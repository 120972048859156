import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//已审核
export function listApproved(data) {
  return fetch({
    url: '/czy-project/projectInfo/bif/listApproved',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//待审核
export function listToApprove(data) {
  return fetch({
    url: '/czy-project/projectInfo/bif/listToApprove',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//获取审核信息
export function getApproveInfo(data) {
  return fetch({
    url: '/czy-project/projectInfo/bidInvitation/getApproveInfo',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//提交审核
export function approve(data) {
  return fetch({
    url: '/czy-project/projectInfo/bidInvitation/approve',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//获取批注人
export function markUsers(data) {
  return fetch({
    url: '/czy-project/projectInfo/markUsers',
    method: 'post',
    data: JSON.stringify(data)
  });
}


/**
 * @desc 结果公告
 */
import { fetch } from '../../../fetch' // 引入fecth.js
const prefix = 'czy-busi'

//结果公告待发布/审核中/审核驳回/已发布信息列表查询 ( 待审核页面操作栏显示 "审核" 已审核显示"查看")
export function getList(data) {
    return fetch({ url: `/${prefix}/projectNoticeInfoEntity/notice/list`, method: 'post', data, })
}

// （采购中心）结果公告待审核/已审核列表查询 ( 待审核页面操作栏显示 "审核" 已审核显示"查看")
export function queryResultNotice(data) {
    return fetch({ url: `/${prefix}/projectNoticeInfoEntity/query/resultNotice`, method: 'post', data, })
}

//  结果公告发布
export function relaseResultNotice(params) {
    return fetch({ url: `/${prefix}/projectNoticeInfoEntity/relase/resultNotice`, method: 'get', params, })
}

//结果公告审核
export function examineNotice(params) {
    return fetch({ url: `/${prefix}/projectNoticeInfoEntity/examine/resultNotice`, method: 'get', params, })
}

// 结果公告退回
export function returnNotice(data) {
    return fetch({ url: `/${prefix}/projectNoticeInfoEntity/return/notice`, method: 'post', data, })
}

// 结果公告退回 审核
export function examineReturnNotice(data) {
    return fetch({ url: `/${prefix}/projectNoticeInfoEntity/examine/returnNotice`, method: 'post', data, })
}

//当前代理机构发布公告是否需要审核流程
export function getPublishAudit(params) {
    return fetch({ url: `/${prefix}/projectNoticeInfoEntity/notice/publishAudit`, method: 'get', params, })
}

// 终止公告发布
export function relaseEndNotice(params) {
    return fetch({ url: `/${prefix}/projectNoticeInfoEntity/relase/endNotice`, method: 'get', params, })
}

// 获取当前项目的公告类型
export function getNoticeType(id) {
    return fetch({ url: `/${prefix}/projectNoticeInfoEntity/notice/getNoticeType?id=${id}`, method: 'get',})
}
import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//列表
export function list(data) {
  return fetch({
    url: '/czy-admin/template/bidNotice/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//列表
export function listDljg(data) {
  return fetch({
    url: '/czy-busi/project/outbid/noticeTemplate/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//重置
export function reset(data) {
  return fetch({
    url: '/czy-busi/project/outbid/noticeTemplate/reset',
    method: 'post',
    data: JSON.stringify(data)
  });
}
import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//已生成列表
export function listDone(data) {
  return fetch({
    url: '/czy-project/projectInfo/build/listDone',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//待生成列表
export function listToDo(data) {
  return fetch({
    url: '/czy-project/projectInfo/build/listToDo',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//删除
export function remove(data) {
  return fetch({
    url: '/czy-project/projectInfo/build/remove',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//获取所有计量单位
export function listAll(params) {
  return fetch({
    url: '/czy-admin/config/unit/listAll',
    method: 'get',
    params: params
  });
}
//采购类别数据树
export function tree(params) {
  return fetch({
    url: '/czy-admin/config/item/tree',
    method: 'get',
    params: params
  });
}

//采购类别数据树
export function projecTree(params) {
  return fetch({
    url: '/czy-admin/config/item/projecTree',
    method: 'get',
    params: params
  });
}
//项目生成详情
export function detail(data) {
  return fetch({
    url: '/czy-project/projectInfo/build/detail',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//生成项目
export function save(data) {
  return fetch({
    url: '/czy-project/projectInfo/build/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//项目退回
export function back(data) {
  return fetch({
    url: '/czy-project/projectInfo/build/back',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//采购清单详情
export function bidPurchaseDetail(data) {
    return fetch({
      url: '/czy-project/projectInfo/build/bidPurchaseListdetail',
      method: 'post',
      data: JSON.stringify(data)
    });
}
//采购清单保存
export function bidPurchaseSave(data) {
    return fetch({
      url: '/czy-project/projectInfo/build/saveBidPurchaseList',
      method: 'post',
      data: JSON.stringify(data)
    });
}
//采购清单删除
export function bidPurchaseDel(data) {
    return fetch({
      url: '/czy-project/projectInfo/build/delBidPurchaseList',
      method: 'post',
      data: JSON.stringify(data)
    });
}
// 工作台 -  供应商
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

// 供应商统计
export function getStatistics(params) {
  return fetch({
    url: `/${prefix}/workbench/provide/statistics`,
    method: 'get',
    params
  })
}
// 项目列表
export function getProvideList(data) {
  return fetch({
    url: `/${prefix}/workbench/provide/list`,
    method: 'post',
    data
  })
}
// 工作台 开标一览表列表
export function queryBidOpeningSchedule(data) {
  return fetch({
    url: `/${prefix}/workbench/provide/bidOpeningSchedule`,
    method: 'post',
    data
  })
}
// 工作台 中标/未中标通知书列表
export function queryNotice(data) {
  return fetch({
    url: `/${prefix}/workbench/provide/notice`,
    method: 'post',
    data
  })
}




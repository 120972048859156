/**
 * @desc  委托协议签订 接口方法文件
 */
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'
const prefix2 = 'czy-project'

/**
 * @desc 查询列表(采购入确认的)
 */
export function getList(data = {}) {
  return fetch({
    url: `/${prefix}/purchaseConfirmation/list`,
    method: 'post',
    data,
  })
}
/**
 * @desc 采购结果查看已确认（非采购入确认的）
 */
export function getList2(data = {}) {
  return fetch({
    url: `/${prefix}/purchaseConfirmation/list2`,
    method: 'post',
    data,
  })
}
/**
 * @desc 详情
 */
export function getInfo(data = {}) {
  return fetch({
    url: `/${prefix}/purchaseConfirmation/info`,
    method: 'post',
    data,
  })
}
/**
 * @desc 代理机构 评审情况说明
 */
export function getInfo2(data = {}) {
  return fetch({
    url: `/${prefix}/purchaseConfirmation/info2`,
    method: 'post',
    data,
  })
}
/**
 * @desc 在线生成并填写函书
*/
export function onlineCreateLetter(data = {}) {
  return fetch({
    url: `/${prefix}/purchaseConfirmation/onlineCreateLetter`,
    method: 'post',
    data,
  })
}
/**
 * @desc 保存提交
*/
export function saveCommit(data = {}) {
  return fetch({
    url: `/${prefix}/purchaseConfirmation/saveCommit`,
    method: 'post',
    data,
  })
}
/**
 * @desc 代理机构审核保存
*/
export function auditSave(data = {}) {
  return fetch({
    url: `/${prefix}/purchaseConfirmation/auditSave`,
    method: 'post',
    data,
  })
}
/**
 * @desc 获取项目的所有包
*/
export function getPackage(data = {}) {
  return fetch({
    url: `/${prefix2}/projectInfo/listBaohaos`,
    method: 'post',
    data,
  })
}
/**
 * @desc 获取项目的所有包
*/
export function viewAll(data = {}) {
  return fetch({
    url: `/${prefix}/reviewResult/viewAll`,
    method: 'post',
    data,
  })
}

// 添加下载记录
export function addDownloadRecord(data) {
  return fetch({
    url: `/${prefix}/downloadRecord/add`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 获取下载记录
export function queryDownloadRecord(data) {
  return fetch({
    url: `/${prefix}/purchaseConfirmation/queryDownloadRecordList`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 下载文件
export function downloadFile(data) {
  return fetch({
    url: `/${prefix}/purchaseConfirmation/download`,
    method: 'post',
    data: JSON.stringify(data),
    responseType: 'blob'
  })
}
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'
const prefix2 = 'czy-busi'
// const prefix2 = 'crIp'
// const prefix2 = 'ylr8080'
// 专家签到/回避列表及流程总状态查询
export function queryCheckInMessage(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/checkInMessage/query`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

// 投标供应商信息列表查询
export function queryBiddingSupplierMessage(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/biddingSupplierMessage/query`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

// // 当前专家签到/回避信息记录查询
// export function queryCheckInRecord(data) {
//   return fetch({
//     url: `/${prefix}/bid/evaluate/checkInRecord/query`,
//     method: 'post',
//     data: JSON.stringify(data),
//   })
// }

// 专家签到-提交
export function commitCheckInMessage(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/checkInMessage/commit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 专家推荐组长信息列表及流程总状态查询
export function queryRecommendLeaderMessage(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/recommendLeaderMessage/query`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

// 当前专家推荐组长记录查询
export function queryRecommendLeaderRecord(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/recommendLeaderRecord/query`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

// 推荐组长-专家信息列表查询
export function queryRecommendLeaderExpertList(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/recommendLeaderExpertList/query`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

// 推荐组长-提交
export function commitRecommendLeader(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/recommendLeader/commit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 当前专家信息查询（是否为组长、当前状态等信息）
export function queryCurrExpertInfo(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/currExpertInfo/query`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

// 有评分框架协议
export function queryScoreFrame(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/scoreFrame`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

// 有评分非框架协议
export function queryScoreNoFrame(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/scoreNoFrame`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

// 无评分框架协议
export function queryNoScoreFrame(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/noScoreFrame`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

// 无评分非框架协议
export function queryNoScoreNoFrame(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/noScoreNoFrame`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

// 查看报价
export function querySupplierQuotationList(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/supplierQuotationList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 查看报价明细
export function querySupplierQuotationDetailList(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/supplierQuotationDetailList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 查看供应商二次报价列表
export function querySupplierSecondaryQuotationList(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/supplierSecondaryQuotationList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 查看分项报价供应商列表
export function evaluateProviders(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/quotationDetail/providers`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 报价废除供应商
export function wasteQuoteSupplier(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/quoteSupplier/waste`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 查看量价关系
export function queryQuantityPriceList(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/quantityPriceList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 查看包预算
export function queryPackageBudgetList(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/packageBudgetList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 查看采购文件
export function queryProcurementDocuments(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/procurementDocuments/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 查看更正公告
export function queryCorrectionNotice(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/correctionNotice/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 获取原因
export function getReasonList(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/dieOutReasonList`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 有无评分 框架协议保存
export function frameSave(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/frameSave`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 有无评分 非框架协议保存
export function noFrameSave(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/noFrameSave`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 保存专家意见
export function saveOpinion(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/saveOpinion`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 本包评审结束
export function getNoDoneExperts(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/getNoDoneExperts`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 本包评审结束
export function endReview(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/endReview`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 判断是否全部包评审完成
export function finishAll(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/finishAll`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 询标 获取本包供应商
export function getSupplierInfo(data) {
  return fetch({
    url: `/${prefix2}/bid/inquiry/supplierList`,
    method: 'post',
    data: JSON.stringify(data),
  })
}


// 询标记录查看页面保存
export function saveInquiry(data) {
  return fetch({
    url: `/${prefix2}/bid/inquiry/save`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 询标记录查看页面
export function lookInquiry(data) {
  return fetch({
    url: `/${prefix2}/bid/inquiry/look`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 询标记录查看页面
export function lookAll(data) {
  return fetch({
    url: `/${prefix2}/bid/inquiry/lookAll`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 询标记录列表
export function getBidList(data) {
  return fetch({
    url: `/${prefix2}/bid/inquiry/list`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}
// 询标记录查看页面 本轮提问结束
export function finishInquiry(data) {
  return fetch({
    url: `/${prefix2}/bid/inquiry/finish`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 询标记录查看页面 转供应商网上回答
export function toSupplierAnswersOnline(data) {
  return fetch({
    url: `/${prefix2}/bid/inquiry/toSupplierAnswersOnline`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 询标记录导出选择列表
export function exportLook(data) {
  return fetch({
    url: `/${prefix2}/bid/inquiry/export/look`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 询标记录导出下载
export function exportDownload(data) {
  return fetch({
    url: `/${prefix2}/bid/inquiry/export/download`,
    method: 'post',
    data: JSON.stringify(data),
    responseType: 'blob'
  })
}
// 询标记录导出批量下载
export function batchDownload(data) {
  return fetch({
    url: `/${prefix2}/bid/inquiry/export/batchDownload`,
    method: 'post',
    data: JSON.stringify(data),
    responseType: 'blob'
  })
}

// 删除
export function deleteBid(params) {
  return fetch({
    url: `/${prefix2}/bid/inquiry/delete`,
    method: 'get',
    params,
  })
}

// 转全体供应商网上回答
export function toAllAnswersOnline(data) {
  return fetch({
    url: `/${prefix2}/bid/inquiry/toAllAnswersOnline`,
    method: 'post',
    data,
  })
}

/**
 * @desc 实质性条款变动问题 专家审核查看列表
*/
// 实质性条款变动问题 专家审核查看列表
export function getClauseChange(data) {
  return fetch({
    url: `/${prefix2}/bid/clauseChange/pageInfo`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 实质性条款变动问题 提交
export function submitClauseChange(data) {
  return fetch({
    url: `/${prefix2}/bid/clauseChange/submit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 实质性条款变动问题 审核
export function auditClauseChange(data) {
  return fetch({
    url: `/${prefix2}/bid/clauseChange/audit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 实质性条款变动问题 列表（供应商端）
export function getClauseChangeList(data) {
  return fetch({
    url: `/${prefix2}/bid/clauseChange/supplier/list`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 实质性条款变动问题 回复详情
export function getReplyInfo(data) {
  return fetch({
    url: `/${prefix2}/bid/clauseChange/reply/info`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 实质性条款变动问题 回复
export function replyClauseChange(data) {
  return fetch({
    url: `/${prefix2}/bid/clauseChange/reply`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 有打分 暗标 待评分结束
export function darkBid(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/darkBid`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 本包结果确认
export function confirmationScore(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/confirmation`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 获取保存状态
export function getSaveStatus(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/getSaveStatus`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 评分结束
export function endScore(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/endScore`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 澄清记录列表
export function getClearList(data) {
  return fetch({
    url: `/${prefix2}/bid/clear/list`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}
// 澄清记录查看页面
export function lookClear(data) {
  return fetch({
    url: `/${prefix2}/bid/clear/look`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}
// 澄清记录保存
export function saveClear(data) {
  return fetch({
    url: `/${prefix2}/bid/clear/saveByExpert`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 澄清记录删除
export function deleteClear(data) {
  return fetch({
    url: `/${prefix2}/bid/clear/delClear`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 查询复核结果
export function reportResult(data) {
  return fetch({
    url: `/${prefix2}/bidResultConfirmed/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 查询当前复核保存信息
export function reportSaved(data) {
  return fetch({
    url: `/${prefix2}/bidResultConfirmed/queryLatestCommitted`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 保存复核信息
export function commitReport(data) {
  return fetch({
    url: `/${prefix2}/bidResultConfirmed/save`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 重评
export function againReview(data) {
  return fetch({
    url: `/${prefix2}/bidResultConfirmed/againReview`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 组员确认评审复核
export function affirm(data) {
  return fetch({
    url: `/${prefix2}/bidResultConfirmed/affirm`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 下载评审报告
export function getBidReport(data) {
  return fetch({
    url: `/${prefix2}/bidResultConfirmed/getBidReport`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 先生成表清单文件
export function generateBaoFiles(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/generateBaoFiles`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 轮询查生成表清单文件-本包评审结束-生成表清单文件-获取运行结果
export function getResultForBaoFiles(data) {
  return fetch({
    url: `/${prefix2}/scoreEntry/getResultForBaoFiles`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

//查询uuid
export function querySavedUnCommitted(data) {
  return fetch({
    url: `/${prefix2}/bidResultConfirmed/querySavedUnCommitted`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

//网路硬件信息查看
export function listTenderEnv(data) {
  return fetch({
    url: `/${prefix2}/bid/evaluate/listTenderEnv`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}
// 工作台 -  代理机构
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'crIp'
const prefix = 'czy-busi'

// 待办列表
export function queryWorkbenchList(data) {
  return fetch({
    url: `/${prefix}/workbench/workbenchList`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 项目列表
export function getProjectList(data) {
  return fetch({
    url: `/${prefix}/workbench/projectList`,
    method: 'post',
    data,
  })
}
// 待办置顶列表
export function getToDoTopList(params) {
  return fetch({
    url: `/${prefix}/toDonePinned/list`,
    method: 'get',
    params,
  })
}
// 待办置顶保存
export function saveToDoTop(data) {
  return fetch({
    url: `/${prefix}/toDonePinned/save`,
    method: 'post',
    data,
  })
}

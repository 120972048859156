// 差错行为公示
import { fetch } from '../../../fetch' // 引入fecth.js
const prefix = 'czy-busi'


export function mistakeNoticeQuery(data) {// 差错行为公示分页查询
    return fetch({ url: `/${prefix}/mistakeNotice/list`, method: 'post', data: JSON.stringify(data), })
}

export function mistakeNoticeSave(data) {// 保存差错行为公示
    return fetch({ url: `/${prefix}/mistakeNotice/save`, method: 'post', data: JSON.stringify(data), })
}

export function mistakeNoticeDelete(data) {// 保存差错行为公示
    return fetch({ url: `/${prefix}/mistakeNotice/delete`, method: 'post', data: JSON.stringify(data), })
}

export function mistakeNoticePublish(data) {// 发布差错行为公示
    return fetch({ url: `/${prefix}/mistakeNotice/publish`, method: 'post', data: JSON.stringify(data), })
}
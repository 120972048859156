import { fetch } from '../../../fetch'; // 引入fecth.js

//待确认
export function listToConfirm(data) {
  return fetch({
    url: '/czy-project/projectInfo/bif/listToConfirm',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//已确认
export function listConfirmed(data) {
  return fetch({
    url: '/czy-project/projectInfo/bif/listConfirmed',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//详情
export function shortInfo(data) {
  return fetch({
    url: '/czy-project/projectCommon/shortInfo',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//审核详情
export function confirmBifInfo(data) {
  return fetch({
    url: '/czy-project/projectInfo/bidInvitation/confirmBifInfo',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//采购文件确认
export function confirm(data) {
  return fetch({
    url: '/czy-project/projectInfo/bidInvitation/confirm',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//项目流转日志
export function listProjectLogByProjectId(data) {
  return fetch({
    url: '/czy-project/projectCommon/listProjectLogByProjectId',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function listProjectLogByBusi(data) {
  return fetch({
    url: '/czy-project/projectCommon/listProjectLogByBusi',
    method: 'post',
    data: JSON.stringify(data)
  });
}

export function confirmBifRollBack(data) {//采购文件确认退回
  return fetch({ url: '/czy-project/projectInfo/bidInvitation/confirmBifRollBack', method: 'post', data: JSON.stringify(data) });
}
import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//项目信息
export function listDemandPub(data) {
  return fetch({
    url: '/czy-project/projectInfo/build/listDemandPub',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//待制作
export function listToMake(data) {
  return fetch({
    url: '/czy-project/projectInfo/dmandPub/listToMake',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//已制作
export function listMaked(data) {
  return fetch({
    url: '/czy-project/projectInfo/dmandPub/listMaked',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//需求公示详情
export function detail(data) {
  return fetch({
    url: '/czy-busi/biDmand/detail',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//需求公示详情保存
export function save(data) {
  return fetch({
    url: '/czy-busi/biDmand/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//资料审查详情
export function qualificationFstRoundDetail(data) {
  return fetch({
    url: '/czy-busi/biDmand/qualificationFstRound/detail',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//资料审查保存
export function qualificationFstRoundSave(data) {
  return fetch({
    url: '/czy-busi/biDmand/qualificationFstRound/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 需求公告详情
export function annoDetail(data) {
  return fetch({
    url: '/czy-busi/biDmand/anno/detail',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 需求公告发布
export function annoRelease(data) {
  return fetch({
    url: '/czy-busi/biDmand/anno/release',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 需求公告保存
export function annoSave(data) {
  return fetch({
    url: '/czy-busi/biDmand/anno/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//废标 
export function abolition(data) {
  return fetch({
    url: '/czy-busi/biDmand/abolition',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//专家列表页信息
export function expertsPageInfo(params) {
  return fetch({
    url: '/czy-busi/projectExperts/expertsPageInfo',
    method: 'get',
    params: params
  });
}
//专家列表页信息-需求公示
export function expertsPageInfoXqgs(params) {
  return fetch({
    url: '/czy-busi/projectExpertsXqgs/expertsPageInfo',
    method: 'get',
    params: params
  });
}
//下拉选择专家
export function expertsChooseList(data) {
  return fetch({
    url: '/czy-busi/projectExperts/expertsChooseList',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function expertsChooseListXqgs(data) {
  return fetch({
    url: '/czy-busi/projectExpertsXqgs/expertsChooseList',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//新增专家
export function add(data) {
  return fetch({
    url: '/czy-busi/projectExperts/add',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function addXqgs(data) {
  return fetch({
    url: '/czy-busi/projectExpertsXqgs/add',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//编辑专家
export function update(data) {
  return fetch({
    url: '/czy-busi/projectExperts/update',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function updateXqgs(data) {
  return fetch({
    url: '/czy-busi/projectExpertsXqgs/update',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//获取专家
export function getProjectExperts(params) {
  return fetch({
    url: '/czy-busi/projectExperts/getProjectExperts',
    method: 'get',
    params: params
  });
}
export function getProjectExpertsXqgs(params) {
  return fetch({
    url: '/czy-busi/projectExpertsXqgs/getProjectExperts',
    method: 'get',
    params: params
  });
}
//删除专家
export function deleteProjectExperts(data) {
  return fetch({
    url: '/czy-busi/projectExperts/deleteProjectExperts',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function deleteProjectExpertsXqgs(data) {
  return fetch({
    url: '/czy-busi/projectExpertsXqgs/deleteProjectExperts',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//生成随机密码
export function generatePassword(params) {
  return fetch({
    url: '/czy-busi/projectExperts/generatePassword',
    method: 'get',
    params: params
  });
}
export function generatePasswordXqgs(params) {
  return fetch({
    url: '/czy-busi/projectExpertsXqgs/generatePassword',
    method: 'get',
    params: params
  });
}

//核验身份
export function verify(data) {
  return fetch({
    url: '/czy-busi/projectExperts/verify',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function verifyXqgs(data) {
  return fetch({
    url: '/czy-busi/projectExpertsXqgs/verify',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//专家列表保存
export function saveAffirmState(data) {
  return fetch({
    url: '/czy-busi/projectExperts/saveAffirmState',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//专家列表保存-需求公示
export function saveAffirmStateXqgs(data) {
  return fetch({
    url: '/czy-busi/projectExpertsXqgs/saveAffirmState',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//劳务领取表
export function laborClaimForm(data) {
  return fetch({
    url: '/czy-busi/projectExperts/laborClaimForm',
    method: 'post',
    data: JSON.stringify(data),
    responseType: "blob"
  });
}

//专家录入查询专家签名状态
export function queryExpertSignStatus(data) {
  return fetch({
    url: '/czy-busi/projectExperts/queryExpertSignStatus',
    method: 'post',
    data: JSON.stringify(data),
  });
}

//获取专家签名二维码
export function commonDiffInfo(data) {
  return fetch({
    url: '/czy-admin/config/commonDiff/info',
    method: 'post',
    data: JSON.stringify(data),
  });
}

//从汇聚获取专家名单
export function fetchHjExperts(data) {
  return fetch({
    url: '/czy-busi/projectExperts/fetchHjExperts',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//是征集项目时，获取采购文件处生成的采购文件
export function getDetailListByProjectId(params) {
  return fetch({
    url: '/czy-busi/bif/purchaseFile/getDetailListByProjectId',
    method: 'get',
    params: params
  });
}

//需求公示退回(需求公示验证失败和审核通过后)
export function dmandPubReback(params) {
  return fetch({
    url: '/czy-busi/biDmand/dmandPub/dmandPubReback',
    method: 'post',
    data: params
  });
}
import { fetch } from '../../../fetch'; // 引入fecth.js
import qs from "qs"; // 引入qs

//列表
export function getCertList(params) {
  return fetch({
    url: '/czy-admin/bidAdmin/getCertList',
    method: 'post',
    params: params
  });
}
//登录的扫码列表
export function getCodeCertList(params) {
  return fetch({
    url: '/czy-admin/bidAdmin/getCodeCertList',
    method: 'post',
    params: params
  });
}
export function getCaImage(data) {
  return fetch({
    url: '/czy-ca/camodel/getCaImage',
    method: 'post',
    data: JSON.stringify(data)
  });
}

// 计算PDF的摘要
export function pdfDigestWithHbcaZoom(data) {
  return fetch({
    url: '/czy-busi/sign/hbca/pdfDigestWithHbcaZoom',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// PDF签名合并
export function pdfMergeSign(data) {
  return fetch({
    url: '/czy-busi/sign/hbca/pdfMergeSign',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//更新文件
export function updateBusiUrl(data) {
  return fetch({
    url: '/czy-busi/sign/hbca/updateBusiUrl',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//标信通盖章文件保存
export function uploadToFileSystem(data) {
  return fetch({ url: '/czy-busi/agency/file/uploadToFileSystem', method: 'post', data: JSON.stringify(data) });
}
import {
  fetch
} from '../../../fetch'; // 引入fecth.js
//列表
export function list(data) {
  return fetch({
    url: '/czy-busi/statistics/project/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 导出
export function listExport(data) {
  return fetch({
    url: '/czy-busi/statistics/project/list/export',
    method: 'post',
    responseType: 'blob',
    data: JSON.stringify(data)
  });
}
//采购计划信息
export function plan(data) {
  return fetch({
    url: '/czy-busi/statistics/project/plan',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//项目基本情况
export function projectInfoREQ(data) {
  return fetch({
    url: '/czy-busi/statistics/project/projectInfo',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//评审小组组成 
export function expert(data) {
  return fetch({
    url: '/czy-busi/statistics/project/expert',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//评审情况说明
export function evaluation(data) {
  return fetch({
    url: '/czy-busi/statistics/project/evaluation',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//报价信息 
export function quotation(data) {
  return fetch({
    url: '/czy-busi/statistics/project/quotation',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//结果公告 
export function resultNotice(data) {
  return fetch({
    url: '/czy-busi/statistics/project/resultNotice',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//合同信息
export function contract(data) {
  return fetch({
    url: '/czy-busi/statistics/project/contract',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//项目基本情况 查看供应商明细
export function supplier(data) {
  return fetch({
    url: '/czy-busi/statistics/project/projectInfo/supplier',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 征集--采购文件确定
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'

/**
 * @desc 采购文件确定列表查询
 */
export function queryList(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/procurementDocumentList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 采购文件确定列表查询
 */
export function queryProviderAdviceList(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/providerAdviceList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 查询专家是否可以进入评审
*/
export function queryCanEnterReview(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/canEnterReview/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 
*/
export function verifyAuthorizationCode(data) {
  return fetch({
    url: `/${prefix}/bid/evaluate/authorizationCode/verify`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 当前专家签到/回避信息记录查询
*/
export function queryCheckInRecord(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/currExpertInfo/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 专家签到/推荐组长信息列表查询
 */
export function queryCheckInMessage(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/checkInMessage/query`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

/**
 * @desc 投标供应商信息列表查询
 */
export function queryBiddingSupplierMessage(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/biddingSupplierMessage/query`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

/**
 * @desc 专家签到-提交
 */
export function commitCheckInMessage(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/checkInMessage/commit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 推荐组长-专家信息列表查询
 */
export function queryRecommendLeaderMessage(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/recommendLeaderMessage/query`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}
/**
 * @desc 推荐组长-专家信息列表查询
 */
export function queryRecommendLeaderExpertList(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/recommendLeaderExpertList/query`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

/**
 * @desc 推荐组长-提交
 */
export function commitRecommendLeader(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/recommendLeader/commit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 文件评审--查询文件初稿及专家审核信息
 */
export function queryDocumentFirstDraftAndRecord(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/documentFirstDraftAndRecord/query`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

/**
 * @desc 文件评审保存/提交
 */
export function saveOrcommitDocumentReview(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/documentReview/saveOrcommit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 查询已提交采购文件评审意见专家列表
 */
export function queryCommittedDocumentReview(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/committedDocumentReview/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
/**
 * @desc 组长退回专家意见
 */
export function returnDocumentReview(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/documentReview/return`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 评审结果--查询汇总意见
 */
export function querySummaryOpinions(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/summaryOpinions/query`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

/**
 * @desc 专家组长保存/提交评审结果
 */
export function saveOrCommitReviewResult(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/reviewResult/saveOrCommit`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 当前专家信息查询（是否为组长）
*/
export function queryCurrExpertInfo(data) {
  return fetch({
    url: `/${prefix}/procurementDocumentReview/currExpertInfo/query`,
    method: 'post',
    data: JSON.stringify(data),
  }, false)
}

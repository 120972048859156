import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//列表
export function enterList(data) {
  return fetch({
    url: '/czy-busi/project/question/enterList',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 获取质疑详情
export function infoREQ(data) {
  return fetch({
    url: '/czy-busi/project/question/info',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//已登记的所有包号
export function getbaohao(params) {
  return fetch({
    url: '/czy-busi/project/question/baohao',
    method: 'get',
    params: params
  });
}

//生成质疑函 
export function generateQuestionLetter(params) {
  return fetch({
    url: '/czy-busi/project/question/generateQuestionLetter',
    method: 'get',
    params: params
  });
}
//保存提交
export function input(data) {
  return fetch({
    url: '/czy-busi/project/question/input',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//已质疑/project/question/haveList
export function haveList(data) {
  return fetch({
    url: '/czy-busi/project/question/haveList',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//疑问提交列表
export function projectList(data) {
  return fetch({
    url: '/czy-busi/project/enquire/projectList',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//疑问详情
export function enquireInfo(data) {
  return fetch({
    url: '/czy-busi/project/enquire/info',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//疑问提交保存
export function enquireInput(data) {
  return fetch({
    url: '/czy-busi/project/enquire/input',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//招标问题回复保存提交
export function enquireReply(data) {
  return fetch({
    url: '/czy-busi/project/enquire/reply',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//质疑信息撤回修改、撤销、签收、受理、质疑补充材料 
export function updateState(data) {
  return fetch({
    url: '/czy-busi/project/question/updateState',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//招标问题回复
export function enquireList(data) {
  return fetch({
    url: '/czy-busi/project/enquire/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//质疑审核列表
export function auditList(data) {
  return fetch({
    url: '/czy-busi/project/question/auditList',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 
export function check(data) {
  return fetch({
    url: '/czy-busi/project/question/check',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//生成补正通知书
export function generateComplementFile(data) {
  return fetch({
    url: '/czy-busi/project/question/generateComplementFile',
    method: 'post',
    data: JSON.stringify(data)
  });
}
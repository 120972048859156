import {
    fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js
//采购文件待制作列表
export function listToMake(data) {
    return fetch({
        url: '/czy-project/projectInfo/bif/listToMake',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//采购文件待制作列表
export function listMaked(data) {
    return fetch({
        url: '/czy-project/projectInfo/bif/listMaked',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//采购文件制作详情
export function detail(data) {
    return fetch({
        url: '/czy-project/projectInfo/bidInvitation/detail',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//获取所有包号
export function listBaohaos(data) {
    return fetch({
        url: '/czy-project/projectInfo/listBaohaos',
        method: 'post',
        data: JSON.stringify(data)
    });
}
//选择供应商
export function providerSearch(providerName) {
    return fetch({ url: `/czy-admin/provider/search?providerName=${providerName}`, method: 'get', data: {} }, false);
}


//项目信息保存
export function bifSave(data) {
    return fetch({
        url: '/czy-busi/bif/save',
        method: 'post',
        data: JSON.stringify(data)
    });
}
//评标方法详情
export function evaluteRuleDetail(data) {
    return fetch({
        url: '/czy-busi/bif/evaluteRule/detail',
        method: 'post',
        data: JSON.stringify(data)
    });
}
//评标方法保存
export function evaluteRuleSave(data) {
    return fetch({
        url: '/czy-busi/bif/evaluteRule/save',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//开标方法详情 
export function openingBidDetail(data) {
    return fetch({
        url: '/czy-busi/bif/openingBid/detail',
        method: 'post',
        data: JSON.stringify(data)
    });
}

export function exportDownLoad() {//开标-报价明细模板下载
    return fetch({ url: '/czy-busi/bif/quoteDetail/downLoad', method: 'get', responseType: 'blob', });
}

//开标方法保存
export function openingBidSave(data) {
    return fetch({
        url: '/czy-busi/bif/openingBid/save',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//资格审查详情 
export function qulificationDetail(data) {
    return fetch({
        url: '/czy-busi/bif/qulification/detail',
        method: 'post',
        data: JSON.stringify(data)
    });
}

export function getShortBo(data) {
    return fetch({ url: '/czy-project/be/agency/getShortBo', method: 'post', data: JSON.stringify(data) });
}

//资格审查保存
export function qulificationSave(data) {
    return fetch({
        url: '/czy-busi/bif/qulification/save',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//资格审查详情 
export function conformanceDetail(data) {
    return fetch({
        url: '/czy-busi/bif/conformance/detail',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//资格审查保存
export function conformanceSave(data) {
    return fetch({
        url: '/czy-busi/bif/conformance/save',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//响应文件详情 
export function respFileDetail(data) {
    return fetch({
        url: '/czy-busi/bif/respFile/detail',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//响应文件保存
export function respFileSave(data) {
    return fetch({
        url: '/czy-busi/bif/respFile/save',
        method: 'post',
        data: JSON.stringify(data)
    });
}
//公告详情
export function annoDetail(data) {
    return fetch({
        url: '/czy-busi/bif/anno/detail',
        method: 'post',
        data: JSON.stringify(data)
    });
}
//公告保存
export function annoSave(data) {
    return fetch({
        url: '/czy-busi/bif/anno/save',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//复制到其他包
export function copy(data, url) {
    return fetch({
        url: url,
        method: 'post',
        data: JSON.stringify(data)
    });
}

//根据模板类型获取模板数据
export function listByCategory(data) {
    return fetch({
        url: '/czy-admin/template/listByCategory',
        method: 'post',
        data: JSON.stringify(data)
    });
}
//根据模板id查询评分方法模板详情
export function listEvaluatedById(data) {
    return fetch({
        url: '/czy-admin/template/listEvaluatedById',
        method: 'post',
        data: JSON.stringify(data)
    });
}
// 根据模板id查询开标模板详情
export function listOpenedById(data) {
    return fetch({
        url: '/czy-admin/template/listOpenedById',
        method: 'post',
        data: JSON.stringify(data)
    });
}

// 根据模板id查询《资符响》模板详情
export function listCommonById(data) {
    return fetch({
        url: '/czy-admin/template/listCommonById',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//查看已上传文件列表
export function listFileUpload(data) {
    return fetch({
        url: '/czy-busi/bif/listFileUpload',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//转采购人确认
export function turnConfirm(data) {
    return fetch({
        url: '/czy-project/projectInfo/bidInvitation/turnConfirm',
        method: 'post',
        data: JSON.stringify(data)
    });
}
//公告发布
export function release(data) {
    return fetch({
        url: '/czy-busi/bif/anno/release',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//公告发布
export function listAllScores(data) {
    return fetch({
        url: '/czy-admin/template/listAllScores',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//设置响应文件对应项
export function mapRespFile(data) {
    return fetch({
        url: '/czy-busi/bif/conformance/mapRespFile',
        method: 'post',
        data: JSON.stringify(data)
    });
}
//文件下载
export function downLoad(data) {
    return fetch({
        url: '/czy-busi/bif/evaluteRuleTemplate/downLoad',
        method: 'post',
        data: JSON.stringify(data),
        responseType: 'blob',
    });
}
//获取工作日日期
export function getAfterDate(params) {
    return fetch({
        url: '/czy-busi/dictHoliday/getAfterDate',
        method: 'get',
        params: params
    });
}
//获取差异化配置
export function getConfigCommon(params) {
    return fetch({
        url: '/czy-busi/config/getConfigCommon',
        method: 'get',
        params: params
    });
}



// 推荐供应商名单
// 推荐供应商列表
export function recommendQuery(data) {
    return fetch({ url: '/czy-busi/recommend/pageList', method: 'post', data: JSON.stringify(data) });
}


// 采购公告（待发布）—退回
export function annoRollBack(data) {
    return fetch({
        url: '/czy-project/projectInfo/anno/rollBack',
        method: 'post',
        data: JSON.stringify(data)
    });
}

//同步到响应文件格式
export function syncEvaluteRule(data) {
    return fetch({
        url: '/czy-busi/bif/evaluteRule/sync',
        method: 'post',
        data: JSON.stringify(data)
    });
}
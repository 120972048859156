// 投标异常情况分析
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylr8080'
const prefix = 'czy-busi'

// 投标异常情况分析-主列表查询
export function queryList(data) {
  return fetch({
    url: `/${prefix}/projectSearch/analysisOfAbnormalBiddingSituationList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

// 投标异常情况分析-特征码异常明细详情查询
export function queryProjectSearch(data) {
  return fetch({
    url: `/${prefix}/projectSearch/abnormalBiddingSituationDetailList/query`,
    method: 'post',
    data: JSON.stringify(data),
  })
}
// 投标异常情况分析-特征码异常明细详情导出
export function exportTable(data) {
  return fetch({
    url: `/${prefix}/projectSearch/abnormalBiddingSituationDetailList/export`,
    method: 'post',
    data: JSON.stringify(data),
    responseType: 'blob',
  })
}

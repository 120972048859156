import { fetch } from '../../../fetch'; // 引入fecth.js
// 按区划
//列表
export function listByAreaCode(data) {
  return fetch({
    url: '/czy-busi/statistics/finance/listByAreaCode',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 导出
export function listByAreaCodeExport(data) {
  return fetch({
    url: '/czy-busi/statistics/finance/listByAreaCode/export',
    method: 'post',
    responseType: 'blob',
    data: JSON.stringify(data)
  });
}
//按采购方式
export function listByStockWay(data) {
  return fetch({
    url: '/czy-busi/statistics/finance/listByStockWay',
    method: 'post',
    data: JSON.stringify(data)
  });
}
// 导出
export function listByStockWayExport(data) {
  return fetch({
    url: '/statistics/finance/listByStockWay/export',
    method: 'post',
    responseType: 'blob',
    data: JSON.stringify(data)
  });
}
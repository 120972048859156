import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js
//列表
export function evaluateList(data) {
  return fetch({
    url: '/czy-busi/bid/evaluate/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//独立评审的专家资格审查汇总
export function listBySeparate(data) {
  return fetch({
    url: '/czy-busi/be/qualification/listBySeparate',
    method: 'post',
    data: JSON.stringify(data)
  }, false);
}

//独立评审单个供应商的专家资格审查汇总
export function listProviderDetailBySeparate(data) {
  return fetch({
    url: '/czy-busi/be/qualification/listProviderDetailBySeparate',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//提交汇总
export function commitSeparateResult(data) {
  return fetch({
    url: '/czy-busi/be/qualification/commitSeparateResult',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//退回专家资格审查
export function qualificationBack(data) {
  return fetch({
    url: '/czy-busi/be/qualification/back',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//退回专家意见
export function returnOpinion(data) {
  return fetch({ url: '/czy-busi/scoreEntry/returnOpinion', method: 'post', data: JSON.stringify(data) });
}
//退回专家资格审查
export function context(data) {
  return fetch({
    url: '/czy-busi/be/context',
    method: 'post',
    data: JSON.stringify(data)
  }, false);
}
//独立评审的专家资格审查汇总确认
export function confirmSeparateResult(data) {
  return fetch({
    url: '/czy-busi/be/qualification/confirmSeparateResult',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//重新汇总
export function toReGather(data) {
  return fetch({
    url: '/czy-busi/be/qualification/toReGather',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//提交
export function commit(data) {
  return fetch({
    url: '/czy-busi/be/qualification/commit',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//符合分项统计列表（项目维度）
export function conformList(data) {
  return fetch({
    url: '/czy-busi/be/conform/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//符合一键通过
export function conformApproveAll(data) {
  return fetch({
    url: '/czy-busi/be/conform/approveAll',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//符合分项详情（供应商维度）
export function conformDetail(data) {
  return fetch({
    url: '/czy-busi/be/conform/detail',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//符合分项详情（供应商维度）
export function conformSave(data) {
  return fetch({
    url: '/czy-busi/be/conform/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//独立评审的专家符合审查汇总
export function conformListBySeparate(data) {
  return fetch({
    url: '/czy-busi/be/conform/listBySeparate',
    method: 'post',
    data: JSON.stringify(data)
  }, false);
}
//独立评审单个供应商的专家符合审查汇总
export function conformListProviderDetailBySeparate(data) {
  return fetch({
    url: '/czy-busi/be/conform/listProviderDetailBySeparate',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//独立评审的专家符合审查，组长提交汇总
export function conformCommitSeparateResult(data) {
  return fetch({
    url: '/czy-busi/be/conform/commitSeparateResult',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//独立评审的专家符合审查汇总确认
export function conformConfirmSeparateResult(data) {
  return fetch({
    url: '/czy-busi/be/conform/confirmSeparateResult',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//重新汇总
export function conformToReGather(data) {
  return fetch({
    url: '/czy-busi/be/conform/toReGather',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//提交
export function conformCommit(data) {
  return fetch({
    url: '/czy-busi/be/conform/commit',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//退回评审 
export function back(data) {
  return fetch({
    url: '/czy-busi/review/back/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//供应商的专家评分列表 
export function scoreList(data) {
  return fetch({
    url: '/czy-busi/be/score/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//供应商的专家打分提交情况
export function listExpertCommit(data) {
  return fetch({
    url: '/czy-busi/be/score/listExpertCommit',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//供应商的专家评分保存
export function saveOpinion(data) {
  return fetch({
    url: '/czy-busi/be/score/saveOpinion',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//供应商的专家评分提交
export function scoreCommit(data) {
  return fetch({
    url: '/czy-busi/be/score/commit',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//查询供应商企业资质相关信息列表 
export function query(data) {
  return fetch({
    url: '/czy-busi/expertProviderEntity/providerQualificationList/query',
    method: 'post',
    data: JSON.stringify(data)
  }, false);
}
//查询供应商企业资质相关信息列表 
export function providerQualificationListSave(data) {
  return fetch({
    url: '/czy-busi/expertProviderEntity/providerQualificationList/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//响应文件对比
export function responseFileComparisonListQuery(data) {
  return fetch({
    url: '/czy-busi/bid/evaluate/responseFileList/query',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//查看投标文件
export function biddingDocumentsQuery(data) {
  return fetch({
    url: '/czy-busi/bid/evaluate/biddingDocuments/query',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//评分表-分项-查看投标内容
export function providerItemScore(data) {
  return fetch({
    url: '/czy-busi/be/score/providerItemScore',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//价格修正
export function priceUpdate(data) {
  return fetch({
    url: '/czy-busi/be/score/priceUpdate',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//保存落实政府政策评审意见
export function reviewCommentsSave(data) {
  return fetch({
    url: '/czy-busi/expertProviderEntity/reviewComments/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//查询有无提交过
export function reviewCommentsQuery(data) {
  return fetch({
    url: '/czy-busi/expertProviderEntity/reviewComments/query',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//退回专家打分
export function listToBack(data) {
  return fetch({
    url: '/czy-busi/be/result/listToBack',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//退回专家打分提交 
export function resultBack(data) {
  return fetch({
    url: '/czy-busi/be/result/back',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//退回打分详情列表
export function listToResaveScore(data) {
  return fetch({
    url: '/czy-busi/be/result/listToResaveScore',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//供应商的专家重新打分
export function resaveScore(data) {
  return fetch({
    url: '/czy-busi/be/result/resaveScore',
    method: 'post',
    data: JSON.stringify(data)
  });
}

export function recommitScore(data) {
  return fetch({
    url: '/czy-busi/be/result/recommitScore',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//查看明细分
export function listByAllExperts(data) {
  return fetch({
    url: '/czy-busi/be/score/listByAllExperts',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//开启最终报价
export function start(data) {
  return fetch({
    url: '/czy-busi/be/conform/multiPrice/start',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//打分退回查询
export function listBacked(data) {
  return fetch({
    url: '/czy-busi/be/result/listBacked',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//重新计算得分-重新计算价格分
export function calPriceScore(data) {
  return fetch({
    url: '/czy-busi/be/score/calPriceScore',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//本包废标 /rejected/bid/save
export function rejectedBidSave(data) {
  return fetch({
    url: '/czy-busi/rejected/bid/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//导出明细分 
export function derivedDetail(data) {
  return fetch({
    url: '/czy-busi/reviewResult/derivedDetail',
    method: "post",
    data: JSON.stringify(data),
    // responseType: "blob",
  })
}
//查看退回记录
export function backLog(data) {
  return fetch({
    url: '/czy-busi/review/back/log',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//本包结果确认
export function confirmation(data) {
  return fetch({
    url: '/czy-busi/scoreEntry/confirmation',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//获取价格修正
export function listPriceUpdate(data) {
  return fetch({
    url: '/czy-busi/be/score/listPriceUpdate',
    method: 'post',
    data: JSON.stringify(data)
  });
}

//价格修正
export function savePriceUpdate(data) {
  return fetch({
    url: '/czy-busi/be/score/savePriceUpdate',
    method: 'post',
    data: JSON.stringify(data)
  });
}

/**
 * @desc 查询专家是否可以进入评审
*/
export function queryCanEnterReview(data) {
  return fetch({
    url: '/czy-busi/bid/evaluate/canEnterReview/query',
    method: 'post',
    data: JSON.stringify(data),
  })
}

export function queryNoPassRecord(data) {//未通过实质性响应记录
  return fetch({ url: '/czy-busi/bid/clauseChange/noPassRecord', method: 'post', data: JSON.stringify(data), })
}

export function saveProviderScore(data) {//汇聚-保存供应商打分
  return fetch({ url: '/czy-busi/be/score/saveProviderScore', method: 'post', data: JSON.stringify(data), })
}

//延长最终报价时间
export function multiPricedelay(data) {
  return fetch({
    url: '/czy-busi/be/conform/multiPrice/delay',
    method: 'post',
    data: JSON.stringify(data),
  })
}

export function resaveScore1(data) {//汇聚-保存供应商打分-供应商的专家重新打分
  return fetch({ url: '/czy-busi/be/result/resaveScore1', method: 'post', data: JSON.stringify(data), })
}

//专家重新打分提交
export function recommitScore1(data) {
  return fetch({
    url: '/czy-busi/be/result/recommitScore1',
    method: 'post',
    data: JSON.stringify(data)
  });
}
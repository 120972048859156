import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//列表
export function list(data) {
  return fetch({
    url: '/czy-admin/template/scoreExpression/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//保存
export function save(data) {
  return fetch({
    url: '/czy-admin/template/scoreExpression/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
//详情
export function detail(data) {
  return fetch({
    url: '/czy-admin/template/scoreExpression/info',
    method: 'post',
    data: JSON.stringify(data)
  });
}

/*
 * @Author: LiuYang
 * @Date: 2023-12-28 14:48:50
 * @Page: 页面名称
 * @LastEditors: LiuYang
 * @LastEditTime: 2024-01-11 17:10:01
 */
import { fetch } from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//采购文件待批注、下载、上传列表
export function listToDo(data) {
    return fetch({ url: '/czy-project/projectInfo/markBif/listToDo', method: 'post', data: JSON.stringify(data) });
}
//采购文件已批注
export function listMarked(data) {
    return fetch({ url: '/czy-project/projectInfo/markBif/listMarked', method: 'post', data: JSON.stringify(data) });
}
//采购文件已上传列表
export function listUploaded(data) {
    return fetch({ url: '/czy-project/projectInfo/markBif/listUploaded', method: 'post', data: JSON.stringify(data) });
}

//采购公告待发布列表
export function listToRelease(data) {
    return fetch({ url: '/czy-project/projectInfo/bifAnno/listToRelease', method: 'post', data: JSON.stringify(data) });
}

//采购公告已发布列表
export function listReleased(data) {
    return fetch({ url: '/czy-project/projectInfo/bifAnno/listReleased', method: 'post', data: JSON.stringify(data) });
}

//采购文件内审意见统计保存
export function save(data) {
    return fetch({ url: '/czy-busi/bif/interAuthSum/save', method: 'post', data: JSON.stringify(data) });
}

//采购文件退回
export function fileUploadBack(data) {
    return fetch({ url: '/czy-project/projectInfo/fileUpload/back', method: 'post', data: JSON.stringify(data) });
}


/**
 * @desc 采购报告制作
 */
import { fetch } from '../../../fetch' // 引入fecth.js

const prefix = 'czy-busi'
const prefix2 = 'czy-project'
// const prefix = 'ylr8080'
// const prefix2 = 'ylrIp'
/**
 * @desc 项目待（采购）报告/已报告列表查询
 */
export function getList(data) {
  return fetch({
    url: `/${prefix}/procurementReport/procurement/list`,
    method: 'post',
    data,
  })
}
/**
 * @desc 查询项目状态字典信息
 */
export function getStatusList(data) {
  return fetch({
    url: `/${prefix2}/sysStatusEntity/projectStatus/list`,
    method: 'post',
    data,
  })
}
/**
 * @desc 项目待（采购）报告/已报告列表查询
 */
export function getResult(data) {
  return fetch({
    url: `/${prefix}/procurementReport/procurement/result`,
    method: 'post',
    data,
  })
}

/**
 * @desc 采购结果保存/提交
 */
export function saveOrCommit(data) {
  return fetch({
    url: `/${prefix}/procurementReport/procurement/saveOrCommit`,
    method: 'post',
    data,
  })
}

/**
 * @desc 审核结果确认函
 */
export function auditResultLetter(data) {
  return fetch({
    url: `/${prefix}/procurementReport/auditResultLetter/save`,
    method: 'post',
    data,
  })
}

/**
 * @desc 评审小组组成信息查询
 */
export function reviewTeam(data) {
  return fetch({
    url: `/${prefix}/procurementReport/reviewTeam/list`,
    method: 'post',
    data,
  })
}

/**
 * @desc 评审情况说明信息查询
 */
export function reviewSisuation(params) {
  return fetch({
    url: `/${prefix}/procurementReport/reviewSisuation/list`,
    method: 'get',
    params,
  })
}

/**
 * @desc 报价信息查询
 */
export function getQuotation(data) {
  return fetch({
    url: `/${prefix}/procurementReport/quotation/list`,
    method: 'post',
    data,
  })
}

/**
 * @desc 采购报告待/已审核信息列表查询 ( 待审核页面操作栏显示 "审核" 已审核显示"查看")
 */
export function reportExamineList(data) {
  return fetch({
    url: `/${prefix}/procurementReport/reportExamine/list`,
    method: 'post',
    data,
  })
}

/**
 * @desc 采购报告审核 提交 保存审核结果
 */
export function reportExamineCommit(data) {
  return fetch({
    url: `/${prefix}/procurementReport/reportExamineStatus/commit`,
    method: 'post',
    data,
  })
}

/**
 * @desc 采购报告退回 待/已审核信息列表查询 ( 待审核页面操作栏显示 "审核" 已审核显示"查看")
 */
export function reportReturnExamineList(data) {
  return fetch({
    url: `/${prefix}/projectNoticeInfoEntity/noticeReturnExamine/list`,
    method: 'post',
    data,
  })
}

/**
 * @desc 采购报告审核
 */
export function examineReport(data) {
  return fetch({
    url: `/${prefix}/procurementReport/examine/report`,
    method: 'post',
    data,
  })
}

/**
 * @desc 
*/

export function returnNotice(data) {
  return fetch({
    url: `/${prefix}/projectNoticeInfoEntity/examine/returnNotice`,
    method: 'post',
    data,
  })
}

/**
 * @desc 结果/终止公告查询(生成信息)
*/
export function queryResultNotice(data) {
  return fetch({
    url: `/${prefix}/projectNoticeInfoEntity/query/resultNotice`,
    method: 'post',
    data,
  })
}

/**
 * @desc 结果/终止公告保存
*/
export function saveResultNotice(data) {
  return fetch({
    url: `/${prefix}/projectNoticeInfoEntity/save/resultNotice`,
    method: 'post',
    data,
  })
}

/**
 * @desc 评审情况说明
*/
export function getInfo(data) {
  return fetch({
    url: `/${prefix}/procurementReport/review/detail/info`,
    method: 'post',
    data,
  })
}

/**
 * @desc 采购文件制作项目退回
*/
export function bifMakeRollBack(data) {
  return fetch({
    url: `/${prefix2}/projectInfo/bif/bifMakeRollBack`,
    method: 'post',
    data,
  })
}

/**
 * @desc 查询采购文件详情 
*/
export function detailAdvace(data) {
  return fetch({
    url: `/${prefix}/bif/purchaseFile/detail`,
    method: 'post',
    data,
  })
}

/**
 * @desc 查询采购文件详情 -采购文件清空已合成文件信息
*/
export function clear(data) {
  return fetch({
    url: `/${prefix}/bif/purchaseFile/clear`,
    method: 'post',
    data,
  })
}

/**
 * @desc 生成采购文件
*/
export function create(data) {
  return fetch({
    url: `/${prefix}/bif/purchaseFile/create`,
    method: 'post',
    data,
  })
}

/**
 * @desc 采购文件在线编辑-保存并转ofd
*/
export function saveToOfd(data) {
  return fetch({
    url: `/${prefix}/bif/purchaseFile/saveToOfd`,
    method: 'post',
    data,
  }, true, 120000)
}

/**
 * @desc 采购文件制作保存校验
*/
export function check(data) {
  return fetch({
    url: `/${prefix}/bif/purchaseFile/check`,
    method: 'post',
    data,
  })
}

/**
 * @desc 采购文件保存
*/
export function update(data) {
  return fetch({
    url: `/${prefix}/bif/purchaseFile/update`,
    method: 'post',
    data,
  })
}

/**
 * @desc 采购文件制作提交
*/
export function submit(data) {
  return fetch({
    url: `/${prefix}/bif/submit`,
    method: 'post',
    data,
  })
}

/**
 *  @desc 采购公告-保存并生成采购文件-获取运行结果
 */
export function getResultForNotice(data) {
  return fetch({
    url: `/${prefix}/bif/purchaseFile/getResultForNotice`,
    method: 'get',
    params: data,
  }, false)
}

/**
 * @desc 采购公告发布查询采购文件详情 
*/
export function detailForNotice(data) {
  return fetch({
    url: `/${prefix}/bif/purchaseFile/detailForNotice`,
    method: 'post',
    data,
  })
}
/**
 * @desc 手动上传采购文件
*/
export function purchaseFileupload(data) {
  return fetch({
    url: `/${prefix}/bif/purchaseFile/upload`,
    method: 'post',
    data,
  })
}

/**
 * @desc 采购文件制作-采购清单详情
 */
export function bidPurchaseListDetailForBif(data) {
  return fetch({
    url: `/${prefix2}/projectInfo/bif/bidPurchaseListDetailForBif`,
    method: 'post',
    data,
  })
}

/**
 * @desc 采购文件制作-采购清单保存
 */
export function saveBidPurchaseListForBif(data) {
  return fetch({
    url: `/${prefix2}/projectInfo/bif/saveBidPurchaseListForBif`,
    method: 'post',
    data,
  })
}
/**
 * @desc 参与投标 - 项目登记
 */
import { fetch } from '../../../fetch' // 引入fecth.js

// const prefix = 'ylrIp'
const prefix = 'czy-project'
/**
 * @desc （供应商）项目待/已登记列表查询
 */
export function getList(data) {
  return fetch({
    url: `/${prefix}/projectSignup/signup/list`,
    method: 'post',
    data: JSON.stringify(data),
  })
}

/**
 * @desc 项目登记详情查询
 */
export function getDetail(params) {
  return fetch({
    url: `/${prefix}/projectSignup/signup/detail`,
    method: 'get',
    params,
  })
}

/**
 * @desc 查询资料审查招标内容信息
*/
export function queryExamineList(data) {
  return fetch({
    url: `/${prefix}/projectSignup/examineList/select`,
    method: 'post',
    data,
  }, false)
}

/**
 * @desc 项目登记保存
 */
export function save(data) {
  return fetch({
    url: `/${prefix}/projectSignup/signup/save`,
    method: 'post',
    data,
  })
}

/**
 * @desc 登记回执信息查询
 */
export function getReceiptInfo(data) {
  return fetch({
    url: `/${prefix}/projectSignup/signup/receiptInfo`,
    method: 'post',
    data,
  })
}

/**
 * @desc 下载标书列表查询
 */
export function getBidList(data) {
  return fetch({
    url: `/${prefix}/projectSignup/signup/bidDocument/list`,
    method: 'post',
    data,
  })
}

/**
 * @desc 在线投标列表
*/
export function getBidOnlineList(data) {
  return fetch({
    url: `/${prefix}/projectSignup/onlineBidding/list`,
    method: 'post',
    data,
  })
}

/**
 * @desc 在线投标详情
*/
export function getBidOnlineDetail(data) {
  return fetch({
    url: `/${prefix}/projectSignup/onlineBidding/detail`,
    method: 'post',
    data,
  })
}

/**
 * @desc 修改下载标书文件状态（点击下载文件请求时调用）
*/

export function updateDownLoadStatus(data) {
  return fetch({
    url: `/${prefix}/projectSignup/updateDownLoadStatus`,
    method: 'post',
    data,
  })
}

/**
 * @desc 查询采购/征集（框架协议）文件更正附件（弹框）列表数据
*/
export function correctionList(data) {
  return fetch({
    url: `/${prefix}/projectSignup/procurementDocuments/correction/list`,
    method: 'post',
    data,
  })
}
/**
 * @desc 历史联系人查询
*/
export function queryHisoricalContacts(data) {
  return fetch({
    url: `/${prefix}/historicalContactsInfo/hisoricalContacts/list`,
    method: 'post',
    data,
  })
}


/*
 * aioxs 接口请求函数
 */
import {fetch} from '../fetch'; // 引入fecth.js
import api from '../url'; // 引入url.js
// import qs from "qs"; // 引入qs

/*
 * /views/system/account 
 * *********************************************************************************************************************
 */
// 账户列表
export function accountList(data) {
	return fetch({
		url: api.accountList,
		method: 'post',
		data: JSON.stringify(data)
	});
}

// 账户角色
export function accountRoles(params) {
	return fetch({
		url: api.accountRoles,
		method: 'get',
		params: params
	});
}

// 确认分配角色
export function accountRelatedRoles(data) {
	return fetch({
		url: api.accountRelatedRoles,
		method: 'post',
		data: JSON.stringify(data)
	});
}

// 账户删除
export function accountDel(params) {
	return fetch({
		url: api.accountDel,
		method: 'get',
		params: params
	});
}

// 账户重设密码
export function accountReset(params) {
	return fetch({
		url: api.accountReset,
		method: 'get',
		params: params
	});
}

// 账户启用/禁用
export function accountEnable(data) {
	return fetch({
		url: api.accountEnable,
		method: 'post',
		data: JSON.stringify(data)
	});
}

// 账户新增/修改
export function accountSave(type, data) {
	return fetch({
		url: type == 'add' ? api.accountAdd : api.accountUpdate,
		method: 'post',
		data: JSON.stringify(data)
	});
}

/*
 * /views/system/messageLog 
 * *********************************************************************************************************************
 */
// 短信日志列表
export function elaSearch(data) {
	return fetch({
		url: api.elaSearch,
		method: 'post',
		data: JSON.stringify(data)
	});
}

/*
 * /views/system/role 
 * *********************************************************************************************************************
 */
// 所有角色列表
export function roleList(data) {
    return fetch({
		url: api.roleList,
		method: 'post',
		data: JSON.stringify(data)
    });
}

// 获取角色资源（权限）
export function findResourceByRoleCode(params) {
	return fetch({
		url: api.findResourceByRoleCode,
		method: 'get',
		params: params
	});
}

// 分配角色资源（权限）
export function roleRelatedResource(data) {
    return fetch({
		url: api.roleRelatedResource,
		method: 'post',
		data: JSON.stringify(data)
    });
}

// 角色删除
export function roleDel(data) {
    return fetch({
		url: api.roleDel,
		method: 'post',
		data: JSON.stringify(data)
    });
}

// 角色新增/修改
export function roleSave(type, data) {
    return fetch({
		url: type == 'add' ? api.roleAdd : api.roleUpdate,
		method: 'post',
		data: JSON.stringify(data)
    });
}

/*
 * /views/system/taskCenter 未知 TODO 待删
 * *********************************************************************************************************************
 */

/*
 * /views/system/venueManage 场地列表 独立成场地模块 见 meetingRoom.js
 * *********************************************************************************************************************
 */

/*
 * /views/system/venueOrder 场地预约 独立成场地模块 见 meetingRoom.js
 * *********************************************************************************************************************
 */

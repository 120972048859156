import {
  fetch
} from '../../../fetch'; // 引入fecth.js
import api from '../../../url'; // 引入url.js

//列表
export function list(data) {
  return fetch({
    url: '/czy-admin/config/area/list',
    method: 'post',
    data: JSON.stringify(data)
  });
}
export function save(data) {
  return fetch({
    url: '/czy-admin/config/area/save',
    method: 'post',
    data: JSON.stringify(data)
  });
}
